import React, { useState, useEffect, useUpdateEffect, useRef } from 'react'
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded'
import { useDispatch, useSelector } from 'react-redux'
import { SET_NAVBAR_SHOW, SET_NAVBAR_HIDE, GET_PROFILE } from '../../constants/actions.js'
import { Typography } from '@material-ui/core'
import useStyles from './styles.js'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import api from '../../api'

const Auth = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [emailTip, setEmailTip] = useState('')
  const [pwd, setPwd] = useState('')
  const [pwdTip, setPwdTip] = useState('')
  const { pageStyle, backIcon, formWrap, formItem, inputField, errorField, submitBtn, registerBtn } = useStyles()
  const emailFirst = useRef(false)
  const pwdFirst = useRef(false)
  const searchParams = new URLSearchParams(window.location.search)
  const redirect = searchParams.get('redirect')
  const [moreTip, setMoreTip] = useState('')

  useEffect(() => {
    dispatch({
      type: SET_NAVBAR_HIDE,
      payload: {
        navbar: 'hide',
      },
    })
    return () => {
      dispatch({
        type: SET_NAVBAR_SHOW,
        payload: {
          navbar: 'show',
        },
      })
    }
  }, [dispatch])

  const validateEmail = () => {
    const emailReg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    let tip = ''
    if (!email || !emailReg.test(email)) {
      tip = 'We cannot find an account with that email address'
    } else {
      tip = ''
    }
    setEmailTip(tip)
    return tip
  }

  useEffect(() => {
    if (!emailFirst.current) {
      emailFirst.current = true
    } else {
      validateEmail(email)
    }
  }, [email])

  useEffect(() => {
    if (!pwdFirst.current) {
      pwdFirst.current = true
    } else {
      validatePwd(pwd)
    }
  }, [pwd])

  const validatePwd = pwd => {
    const pwdReg = /^(?=.*[a-zA-Z]+)(?=.*[0-9]+)[a-zA-Z0-9]{6,20}$/
    let tip = ''
    if (!pwd || !pwdReg.test(pwd)) {
      tip = 'Your password is incorrect'
    } else {
      tip = ''
    }
    setPwdTip(tip)
    return tip
  }

  const signIn = async () => {
    let tip1 = validatePwd(pwd)
    let tip2 = validateEmail(email)

    if (tip1 || tip2) {
      return
    }
    let { data } = await api.login({
      email,
      password: pwd,
    })
    if (!data.code) {
      localStorage.setItem('profile', JSON.stringify(data))
      localStorage.setItem('__booktoken__', data.token)
      dispatch({
        type: GET_PROFILE,
        payload: data,
      })
      history.push('/home')
    } else {
      switch (data.code) {
        case 100001:
          setEmailTip('We cannot find an account with that email address')
          break
        case 100002:
          setPwdTip('Please enter the correct email and password')
          break
        case 100003:
          setMoreTip("Your password is incorrect")
          break
        default:
          break
      }
    }
  }
  return (
    <div className={pageStyle}>
      <div
        style={{
          marginTop: '30px',
          marginLeft: '40px',
        }}
      >
        <ArrowBackRoundedIcon style={{ cursor: 'pointer' }} fontSize="large" color="primary" className={backIcon} onClick={() => history.goBack()} />
      </div>
      <div className={formWrap}>
        <Typography variant="h5">Please sign in below to continue</Typography>

        <div className={formItem}>
          <div htmlFor="">Email</div>
          <input
            maxLength="50"
            className={`${inputField} ${emailTip ? errorField : ''}`}
            placeholder="email"
            onChange={e => setEmail(e.target.value)}
          />
          {emailTip ? <div className="form-error">{emailTip}</div> : null}
        </div>

        <div className={formItem}>
          <div htmlFor="">Password</div>
          <input
            type="password"
            maxLength="20"
            className={`${inputField} ${pwdTip ? errorField : ''}`}
            placeholder="At least 6 characters"
            onChange={e => setPwd(e.target.value)}
          />
          {pwdTip ? <div className="form-error">{pwdTip}</div> : null}
        </div>

        {moreTip ? <div className="form-error">{moreTip}</div> : null}

        <div className={submitBtn} onClick={signIn}>
          Sign in
        </div>

        <Link to={'/register'} style={{ textDecoration: 'none' }}>
          <div className={registerBtn}>Create your account</div>
        </Link>
      </div>
    </div>
  )
}

export default Auth
