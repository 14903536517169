const axios = require("axios");
let _baseUrl = ''
if (process.env.NODE_ENV === "development") {
  _baseUrl = "http://10.20.15.33:3000"
}
const API = axios.create({
  baseUrl: _baseUrl,
});

API.interceptors.request.use((req) => {
  if (localStorage.getItem("__booktoken__")) {
    req.headers.token = localStorage.getItem("__booktoken__");
  }
  return req;
});

API.interceptors.response.use((res) => {
  console.log(res.headers)
  let { token } = res.headers
  if (token) {
    localStorage.setItem('__booktoken__', token)
  }
  if (res.status === 200 && res.data.code === 100010) {
    window.location.href = `/login?redirect=${encodeURIComponent(window.location.href)}`
    return Promise.reject(res)
  }

  return res;
});


const urlBooks = "/books";

const fetchBooks = () => API.get(`${urlBooks}/all`);

const createBookAd = (formData) => API.post(`${urlBooks}/add`, formData);

const addToWishList = (id) => API.patch(`${urlBooks}/${id}/addWishList`, id);

const showBookInfo = (bookId) => API.get(`${urlBooks}/book/${bookId}`);

const updatedIsSold = (bookId) => API.patch(`${urlBooks}/${bookId}/sold`);

const deleteaBook = (bookId) => API.delete(`${urlBooks}/${bookId}`);

const editaBook = (id, formData) => API.patch(`${urlBooks}/${id}`, formData);

const urlUsers = "/users";

const signUp = (formData) => API.post(`${urlUsers}/signUp`, formData);
const signIn = (formData) => API.post(`${urlUsers}/signIn`, formData);

const checkUserValid = (token) => API.post(`${urlUsers}/token-check`, token);
const sendPasswordMail = (email) =>
  API.post(`${urlUsers}/forgot-password`, email);
const resetPassword = (formData) =>
  API.post(`${urlUsers}/reset-password`, formData);

// const verifyEmail = (email) => API.post(`${urlUsers}/verify-email`, email);
const verifiedUser = (token) => API.post(`${urlUsers}/validate-user`, token);

const googleFacebookSignIn = (formData) =>
  API.post(`${urlUsers}/googleFacebookSignIn`, formData);

const getProfile = (id) => API.get(`${urlUsers}/profile/${id}`);
const getRecentUsers = () => API.get(`${urlUsers}/profile/messages`);
const editProfile = (updatedUser) =>
  API.patch(`${urlUsers}/profile`, updatedUser);
const changePassword = (updatedPassword) =>
  API.patch(`${urlUsers}/profile/password`, updatedPassword);

const sendMail = (feedData) => API.post(`${urlUsers}/send-email`, feedData);
// const getWishList = (id) => API.get(`${urlUsers}/wishList`);
const deleteaBookFromWish = (book_id) => API.delete(`${urlUsers}/${book_id}`);

const register = (data) => API.post('/api/auth/register', data);

const verifyEmail = (data) => API.post('/api/auth/verifyEmail', data);

const login = (data) => API.post('/api/auth/login', data)

const logout = () => API.post('/api/auth/signOut')

const editUsername = (data) => API.post('/api/user/editUsername', data)

const getBooks = (data) => API.get('/api/book/list', { params: data })

const getBook = (data) => API.get('/api/book/info', { params: data })

const saveBook = (data) => API.post('/api/book/save', data)

const bookOrder = (data) => API.post('/api/order/create', data)

const getMyBooks = (data) => API.get('/api/book/myBooks', { params: data })

const getMyOrders = (data) => API.get('/api/order/list', { params: data })

const uploadBook = (data) => API.post('/api/admin/bookConfirm', data)

const unUploadBook = (data) => API.post('/api/admin/unShelveBook', data)

const delBook = (data) => API.post('/api/admin/deleteBook', data)

const getOrderInfo = (data) => API.get('/api/order/info', { params: data })

const finishOrder = (data) => API.post('api/admin/orderConfirm', data)

const cancelOrder = (data) => API.post('api/admin/orderCancel', data)

const exportOrder = (data = {}) => API.post('api/admin/exportOrders', data)

module.exports = {
  fetchBooks,
  createBookAd,
  signUp,
  signIn,
  googleFacebookSignIn,
  addToWishList,
  getProfile,
  editProfile,
  showBookInfo,
  updatedIsSold,
  deleteaBook,
  editaBook,
  changePassword,
  sendMail,
  getRecentUsers,
  deleteaBookFromWish,
  sendPasswordMail,
  checkUserValid,
  resetPassword,
  verifyEmail,
  verifiedUser,
  register,
  API,
  login,
  editUsername,
  logout,
  getBooks,
  getBook,
  saveBook,
  bookOrder,
  getMyBooks,
  getMyOrders,
  uploadBook,
  unUploadBook,
  delBook,
  getOrderInfo,
  finishOrder,
  cancelOrder,
  exportOrder,
};
