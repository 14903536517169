import React, { useState, useEffect, useUpdateEffect, useRef } from 'react'
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded'
import { useDispatch, useSelector } from 'react-redux'
import { SET_NAVBAR_SHOW, SET_NAVBAR_HIDE } from '../../constants/actions.js'
import { Grid, Typography } from '@material-ui/core'
import useStyles from './styles.js'
import { Link } from 'react-router-dom'
import api, { bookOrder } from '../../api'
import { useHistory, useParams } from 'react-router-dom'
import style from '../PostAdComponents/style.js'
import UploadIcon from '../../images/upload.png'
import moment from 'moment'

const Auth = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const styles = useStyles()
  const searchParams = new URLSearchParams(window.location.search);
  const [book, setBook] = useState({ status: '' });

  console.log('dddd', searchParams)
  useEffect(() => {
    dispatch({
      type: SET_NAVBAR_HIDE,
      payload: {
        navbar: 'hide',
      },
    })
    return () => {
      dispatch({
        type: SET_NAVBAR_SHOW,
        payload: {
          navbar: 'show',
        },
      })
    }
  }, [])

  useEffect(() => {
    async function loadData() {
      let { data } = await api.getBook({
        id: searchParams.get('id')
      })
      setBook(data)
    }
    loadData()
  }, []);

  const getState = (state) => {
    return {
      1: 'Not listed',
      2: 'Listed',
      3: 'Sold',
    }[state]
  }

  const getFormatTime = (time) => {
    return moment(new Date(time)).format('YYYY-MM-DD HH:mm:ss')
  }

  const formatMonth = (month) => {
    let str = ''
    let year = Math.floor(+month / 12);

    year && (str += `${year} year and`);

    let _month = Math.floor(+month % 12)
    str += `${_month} months`
    return str
  }

  return (
    <div className={styles.pageStyle}>
      <Typography variant="h5" className="text-bold text-center" style={{ fontSize: '36px', marginTop: '100px' }}>
        Uploaded !
      </Typography>
      <Typography className="text-center" style={{ fontSize: '15px', marginTop: '15px' }}>
        Please drop off your book in AWB corner.
      </Typography>

      <Grid container spacing={1} className={styles.totalInfo}>
        <Grid item xs={5}>
          <Typography style={{ fontWeight: 'bold' }}>
            Book Information
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography style={{ fontWeight: 'bold' }}>
            Price
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography style={{ fontWeight: 'bold' }}>
            User Information
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography style={{ fontWeight: 'bold' }}>
            Status
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={0} className={styles.detailInfo}>
        <Grid item xs={5}>
          <div className={styles.bookInfo}>
            <img src={book.cover} alt="" width="190" height="190" />
            <div style={{ marginLeft: '10px' }}>
              <p className={styles.mainText}>{book.title}</p>
              <p>
                <span>Subject : </span>
                <span style={{ color: '#999' }}>{book.subject}</span>
              </p>
              <p>
                <span>Grade : </span>
                <span style={{ color: '#999' }}>{book.grade}</span>
              </p>
              <p>
                <span>Author Name : </span>
                <span style={{ color: '#999' }}>{book.author}</span>
              </p>
              <p>
                <span>Published Time : </span>
                <span style={{ color: '#999' }}>{getFormatTime(book.publish_time)}</span>
              </p>
              <p>
                <span>Using Period : </span>
                <span style={{ color: '#999' }}>{formatMonth(book.used_period)}</span>
              </p>
            </div>
          </div>
        </Grid>
        <Grid item xs={2}>
          <p className={styles.mainText} style={{ marginTop: '15px' }}>$ {book.sale_price}</p>
          <p>
            <span>Market Price : </span>
            <span style={{ color: '#999' }}> ${book.original_price}</span>
          </p>
        </Grid>
        <Grid item xs={3}>
          <p className={styles.mainText} style={{ marginTop: '15px' }}>{book.seller_username}</p>
          <p>
            <span>Email : </span>
            <span style={{ color: '#999' }}>{book.seller_email}</span>
          </p>
          <p>
            <span>Upload Time : </span>
            <span style={{ color: '#999' }}>{getFormatTime(book.update_time)}</span>
          </p>
          <p>
            <span>Drop Off Time : </span>
            <span style={{ color: '#999' }}>{book.drop_off_time}</span>
          </p>

        </Grid>
        <Grid item xs={2}>
          <Typography className={`state-${book.state}`}>
            {getState(book.state)}
          </Typography>
        </Grid>
      </Grid>

      <div className={styles.backToShopBtn} onClick={() => history.push('/shop')}>Back to Shop</div>
    </div >
  )
}

export default Auth
