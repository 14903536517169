import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Typography, Button } from '@material-ui/core'
import useStyles from './styles.js'
import api, { bookOrder } from '../../api'
import { useHistory } from 'react-router-dom'
import style from '../PostAdComponents/style.js'
import UploadIcon from '../../images/upload.png'
import XSelect from '../../comps/select/Select'
import SerachIcon from '../../images/search.png'
import ReactPaginate from 'react-paginate';
import SearchEmptyIcon from '../../images/search-empty-icon.png'
import ClearIcon from '../../images/clear-icon.png'
import ConfrimModal from '../../comps/config-modal/ConfirmModal'
import './style.css'
import moment from 'moment'

const stateMap = {
  1: 'Not listed',
  2: 'Listed',
  3: 'Sold',
}
const statusList = [
  {
    value: 'all',
    label: 'all'
  },
  {
    value: 1,
    label: 'Not listed'
  },
  {
    value: 2,
    label: 'Listed'
  },
  {
    value: 3,
    label: 'Sold'
  }
]

const pageCount = 15
const Auth = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const styles = useStyles()
  const [books, setBooks] = useState([]);
  const [title, setTitle] = useState('');
  const [status, setStatus] = useState([{
    value: 'all',
    label: 'all'
  }]);
  const [count, setCount] = useState(0);
  const [pageOffset, setPageOffset] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const user = useSelector(state => state.user)
  const [currentBook, setCurrentBook] = useState({});

  const getBooks = useCallback(
    async () => {
      let statusValue = status[0].value
      let { data } = await api.getMyBooks({
        title: title,
        state: statusValue === 'all' ? '' : statusValue,
        id: '',
        limit: pageCount,
        offset: pageCount * pageOffset,
      })
      if (data && !data.code) {
        setBooks(data.rows || [])
        setCount(Math.ceil(data.count / pageCount))
      }
    },
    [status, pageOffset],
  )

  useEffect(() => {
    getBooks()
  }, [status, pageOffset, getBooks]);


  const searchBook = () => {
    setPageOffset(0)
    setStatus([{
      value: 'all',
      label: 'all',
    }])
  }

  const handlePageChange = (event) => {
    setPageOffset(event.selected);
  }

  const getStateName = (key) => {
    return (statusList.find(item => item.value === key) || {}).label || ''
  }

  const editBook = (book) => {
    history.push(`/upload-book?id=${book.id}`)
  }
  const upload = async (book) => {
    let { data } = await api.uploadBook({ id: book.id })
    if (!data || !data.code) {
      getBooks()
    }
  }

  const unUpload = async (book) => {

    let { data } = await api.unUploadBook({ id: book.id })
    if (!data || !data.code) {
      getBooks()
    }
  }
  const del = async (book) => {
    setCurrentBook(book)
    setShowModal(true)
  }

  const delBook = async () => {
    let { data } = await api.delBook({ id: currentBook.id })
    if (!data || !data.code) {
      getBooks()
      setShowModal(false)
    }
  }

  const formatMonth = (month) => {
    let str = ''
    let year = Math.floor(+month / 12);

    year && (str += `${year} year and`);

    let _month = Math.floor(+month % 12)
    str += `${_month} months`
    return str
  }

  return (
    <div className={styles.pageStyle}>
      <div className="my-books-search">
        <div>Books</div>
        <div style={{ flex: 1 }}></div>
        <div className={`${styles.formItem} form-item`}>
          <label className="label">Status</label>
          <div style={{
            width: '170px', marginLeft: '10px',
          }}>
            <XSelect
              className='books-select-box'
              options={statusList}
              values={status}
              placeholder='Please select'
              onChange={(value) => setStatus(value)}
              color='#E0C9B4'
            ></XSelect>
          </div>
        </div>
        <div className="search-input-box">
          <input type="text" value={title} placeholder="Search" onChange={(e) => setTitle(e.target.value)} />
          {
            title ? <img src={ClearIcon} alt="" className="clear-icon" onClick={() => setTitle('')} /> : null
          }
          <span onClick={searchBook}>
            <img src={SerachIcon} alt="" className="search-icon" />
          </span>
        </div>
      </div>
      {
        books.length > 0 ?
          <Grid container spacing={2} className={styles.totalInfo}>
            <Grid item xs={4}>
              <Typography >
                Book Information
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography >
                Price
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography >
                User Information
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography >
                Status
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography >
                Operation
              </Typography>
            </Grid>
          </Grid> : null
      }

      {
        books.length > 0 ?
          books.map(book => {
            return <Grid container spacing={2} className={styles.detailInfo}>
              <Grid item xs={4}>
                <div className={styles.bookInfo}>
                  <img src={book.cover} alt="" width="190" height="190" />
                  <div style={{ marginLeft: '10px' }}>
                    <p className={styles.mainText}>{book.title}</p>
                    <p>
                      <span>Subject : </span>
                      <span style={{ color: '#999' }}>{book.subject}</span>
                    </p>
                    <p>
                      <span>Grade : </span>
                      <span style={{ color: '#999' }}>{book.grade}</span>
                    </p>
                    <p>
                      <span>Author Name : </span>
                      <span style={{ color: '#999' }}>{book.author}</span>
                    </p>
                    <p>
                      <span>Published Time : </span>
                      <span style={{ color: '#999' }}>{book.publish_time ? moment(new Date(book.publish_time)).format('YYYY-MM-DD HH:mm:ss') : ''}</span>
                    </p>
                    <p>
                      <span>Using Period : </span>
                      <span style={{ color: '#999' }}>{formatMonth(book.used_period)}</span>
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid item xs={2}>
                <p className={styles.mainText} style={{ marginTop: '10px' }}>$ {book.sale_price}</p>
                <p>
                  <span>Market Price : </span>
                  <span style={{ color: '#999' }}> ${book.original_price}</span>
                </p>
              </Grid>
              <Grid item xs={3}>
                <p className={styles.mainText} style={{ marginTop: '10px' }}>Sam</p>
                <p>
                  <span>Email : </span>
                  <span style={{ color: '#999' }}>{book.seller_email}</span>
                </p>
                <p>
                  <span>Upload Time : </span>
                  <span style={{ color: '#999' }}>{moment(new Date(book.update_time)).format('YYYY-MM-DD HH:mm:ss')}</span>
                </p>
                <p>
                  <span>Drop Off Time : </span>
                  <span style={{ color: '#999' }}>{book.drop_off_time}</span>
                </p>

              </Grid>
              <Grid item xs={1} className={styles.mainText}>
                <Typography className={`status-${book.state}`}>
                  {getStateName(book.state)}
                </Typography>
              </Grid>
              <Grid item xs={2} className={styles.mainText}>
                <div className="btn-operate">
                  {
                    book.state === 1 ?
                      user.role === 10 ?
                        <>
                          <span onClick={() => editBook(book)} className="edit">
                            Edit
                          </span>
                          <span className="su">|</span>
                          <span onClick={() => upload(book)} className="upload">
                            Upload
                          </span>
                          <span className="su">|</span>
                          <span onClick={() => del(book)} className="del">
                            Delete
                          </span>
                        </>
                        : <span onClick={() => editBook(book)} className="edit">
                          Edit
                        </span>
                      : ''
                  }
                  {
                    book.state === 2 ?
                      user.role === 10 ? <>
                        <span onClick={() => editBook(book)} className="edit">
                          Edit
                        </span>
                        <span className="su">|</span>
                        <span onClick={() => unUpload(book)} className="del">
                          Offload
                        </span>
                      </> : ''
                      : ''
                  }
                  {
                    book.state === 3 ? '' : ''
                  }
                </div>
              </Grid>
            </Grid>
          })
          : <div className="search-empty-box">
            <img src={SearchEmptyIcon} alt="" />
            <p>No results </p>
          </div>
      }


      {
        books.length > 0 ? <div className="page-wrap">
          <div style={{ flex: 1 }}></div>
          <ReactPaginate
            previousLabel="Previous"
            nextLabel="Next"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            pageCount={count}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={handlePageChange}
            containerClassName="pagination"
            activeClassName="active"
            forcePage={pageOffset}
          />
        </div> : null
      }

      {
        showModal ?
          <ConfrimModal
            msg="Are you sure you want to delete it?"
            confirmText="Delete"
            onClose={() => setShowModal(false)}
            onConfirm={delBook}
          ></ConfrimModal>
          : null
      }
    </div >
  )
}

export default Auth
