import React, { useState, useEffect, useRef, useCallback } from 'react'
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded'
import { useDispatch, useSelector } from 'react-redux'
import { SET_NAVBAR_SHOW, SET_NAVBAR_HIDE, GET_PROFILE } from '../../constants/actions.js'
import { Typography, Popover, ClickAwayListener, Grid, Checkbox } from '@material-ui/core'
import useStyles from './styles.js'
import api from '../../api/index'
import { useHistory, useParams } from 'react-router-dom'
import './style.css'
import moment from 'moment'
const stateMap = {
  1: 'Ordered',
  2: 'Closed',
  3: 'Completed',
}

const Auth = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const cardBooks = useSelector(state => state.card)
  const styles = useStyles()
  const [drop_off_time, setDrop_off_time] = useState('Monday 12:30-13:00');
  const [booksList, setBooksList] = useState(cardBooks);
  const [orderInfo, setOrderInfo] = useState({});
  const searchParams = new URLSearchParams(window.location.search);


  useEffect(() => {
    const _id = searchParams.get('id') || ''
    async function loadData() {
      let { data } = await api.getOrderInfo({
        id: _id
      })
      setBooksList(data.books)
      setOrderInfo(data)
    }
    _id && loadData()
  }, []);

  return (
    <div className="card-wrap card-order-wrap order-result" style={{ textAlign: 'center' }}>
      <Typography variant="h5" style={{ marginTop: '40px', fontWeight: 'bold', fontSize: '36px' }}>Confirmation !</Typography>

      <Typography style={{ marginBottom: '30px', color: '#261C45' }}>Please pick up your book in AWB corner.</Typography>
      <div style={{ height: '30px' }}></div>
      <div className="card-box">
        <Grid container spacing={0} className="card-list-title">
          <Grid item xs={4}>
            Book Information
          </Grid>
          <Grid item xs={2}>
            Price
          </Grid>
          <Grid item xs={2}>
            Quantity
          </Grid>
          <Grid item xs={2}>
            Subtotal
          </Grid>
          <Grid item xs={2}>
            Status
          </Grid>
        </Grid>
        <div className="order-info">
          <div className="order-item">
            <span style={{ fontWeight: 'bold' }}>Order Time: </span>
            <span>{moment(new Date(orderInfo.create_time)).format('YYYY-MM-DD HH:mm:ss')}</span>
          </div>
          <div className="order-item">
            <span style={{ fontWeight: 'bold' }}>Order No: </span>
            <span>{orderInfo.order_no}</span>
          </div>
          <div className="order-item">
            <span style={{ fontWeight: 'bold' }}>Pick Up Time: </span>
            <span>{orderInfo.pick_up_time}</span>
          </div>

          <div className="order-item">
            <span style={{ fontWeight: 'bold' }}>User Information: </span>
            <span>{orderInfo.buyer_username}, {orderInfo.buyer_email}</span>
          </div>

          <div className="order-item">
            <span style={{ fontWeight: 'bold' }}>User Information: </span>
            <span>{orderInfo.buyer_username}, {orderInfo.buyer_email}, {orderInfo.student_no || ''}</span>
          </div>
        </div>
        {
          booksList.map(book => {
            return <Grid container spacing={0} className="card-list-body" key={book.id}>
              <Grid item xs={4} className="book-info">
                <img src={book.cover} alt="" className="cover" />
                <div className="title">{book.title}</div>
              </Grid>
              <Grid item xs={2}>
                <div>${book.price}</div>
              </Grid>
              <Grid item xs={2}>
                1
              </Grid>
              <Grid item xs={2}>
                <div>${book.price}</div>
              </Grid>
              <Grid item xs={2}>
                <div>{stateMap[book.state]}</div>
              </Grid>
            </Grid>
          })
        }

        <div className="back-shop-btn" onClick={() => history.push('/shop')}>
          Back to Shop
        </div>
      </div>
    </div>
  )
}

export default Auth
