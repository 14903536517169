var items = [
  {
    heading: "Welcome To BookxChanger",
    subHeading: "Your personalized old books exchanging Store",
    description:
      "Hey,Want to Sell/Buy old books , don't know where to go. You have Come at the right place",
    img:
      "./img/png",
  },
];
export default items;
