import React, { useState, useEffect, useUpdateEffect, useRef } from 'react'
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded'
import { useDispatch, useSelector } from 'react-redux'
import { SET_NAVBAR_SHOW, SET_NAVBAR_HIDE } from '../../constants/actions.js'
import { Typography, Popover } from '@material-ui/core'
import useStyles from './styles.js'
import { Link } from 'react-router-dom'
import api, { API } from '../../api'
import { useHistory } from 'react-router-dom'
import XSelect from '../../comps/select/Select'
import { subjectList, gradeList, periodList } from '../../baseData.js'
import 'date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import './style.css'
import UploadIcon from '../../images/upload.png'
import ChooseIcon from '../../images/choose-icon.png'
import ajax from './ajax'


const Auth = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [previewSrc, setPreviewSrc] = useState('');
  const [title, setTitle] = useState('');
  const [titleTip, setTitleTip] = useState('');

  const [grade, setGrade] = useState([]);
  const [gradeTip, setGradeTip] = useState('');

  const [subject, setSubject] = useState([]);
  const [subjectTip, setSubjectTip] = useState('');

  const [publish_time, setPublish_time] = useState(null);
  const [publishTimeTip, setPublishTimeTip] = useState('');

  const [used_period, setUsed_period] = useState([]);
  const [periodTip, setPeriodTip] = useState('');

  const [cover, setCover] = useState('');
  const [coverTip, setCoverTip] = useState('');

  const [sale_price, setSale_price] = useState('');
  const [salePriceTip, setSalePriceTip] = useState('');

  const [original_price, setOriginal_price] = useState('');
  const [originalPriceTip, setOriginalPriceTip] = useState('');

  const [drop_off_time, setDrop_off_time] = useState('Monday 12:30-13:00');

  const [author, setAuthor] = useState('');

  const [ajaxing, setAjaxing] = useState(false)
  const { pageStyle, backIcon, formWrap, formItem, inputField, errorField, submitBtn } = useStyles()
  const initPage = useRef(false)
  const styles = useStyles()
  const searchParams = new URLSearchParams(window.location.search);
  const [hasFile, setHasFile] = useState(false);
  const [tipMsg, setTipMsg] = useState('');
  const [open, setOpen] = useState(false);
  const [bookInfo, setBookInfo] = useState([
    {
      id: 'title',
      label: "Book's name",
      value: '',
      tip: '',
      maxLength: 50,
      req: true,
      validate: v => {
        if (!v) {
          return 'Please enter the book’s name'
        }
        return ''
      },
    },
    {
      id: 'subject',
      label: "Subject",
      value: '',
      tip: '',
      placeholder: 'Please select',
      type: 'select',
      req: true,
      validate: v => {
        if (!v) {
          return 'Please select the corresponding subject'
        }
        return ''
      },
    },
    {
      id: 'grade',
      label: "Grade",
      value: '',
      tip: '',
      placeholder: 'Please select',
      type: 'select',
      req: true,
      validate: v => {
        if (!v) {
          return 'Please select the corresponding grade'
        }
        return ''
      },
    },
    {
      id: 'author',
      label: "Author Name",
      value: '',
      tip: '',
      maxLength: 50,
      validate: () => {
        return ''
      },
    },
    {
      id: 'publish_time',
      label: "Publish Time",
      value: '',
      tip: '',
      type: 'date',
      validate: () => {
        return ''
      },
    },
    {
      id: 'used_period',
      label: "Using period",
      value: '',
      tip: '',
      type: 'select',
      req: true,
      validate: v => {
        if (!v) {
          return 'Please enter the using period'
        }
        return ''
      },
    },
    {
      id: 'cover',
      label: "Book’s photograph",
      value: '',
      tip: '',
      type: 'upload',
      req: true,
      tipText: 'Please upload jpg/jpeg/png images below 512K',
      validate: v => {
        if (!v) {
          return 'Please upload jpg/jpeg/png images below 512K'
        }
        return ''
      },
    },
    {
      id: 'sale_price',
      label: "Market Price",
      value: '',
      tip: '',
      req: true,
      validate: v => {
        if (!v) {
          return 'Please enter the market Price'
        }
        return ''
      },
    },
    {
      id: 'original_price',
      label: "Your Price",
      value: '',
      tip: '',
      req: true,
      validate: v => {
        if (!v) {
          return 'Please enter the your Price'
        }
        return ''
      },
    },
    {
      id: 'drop_off_time',
      label: "drop_off_time",
      value: '',
      req: false,
      tip: 'Monday 12:30-13:00',
      validate: v => {
        return ''
      },
    },
  ])


  useEffect(() => {
    dispatch({
      type: SET_NAVBAR_HIDE,
      payload: {
        navbar: 'hide',
      },
    })
    return () => {
      dispatch({
        type: SET_NAVBAR_SHOW,
        payload: {
          navbar: 'show',
        },
      })
    }
  }, [])

  useEffect(() => {
    let id = searchParams.get('id')

    async function loadData() {
      let { data } = await api.getBook({
        id: searchParams.get('id')
      })

      let _bookInfo = bookInfo.map(item => {
        item.id === 'title' && setTitle(data[item.id])
        item.id === 'author' && setAuthor(data[item.id])
        item.id === 'publish_time' && setPublish_time(data[item.id])
        item.id === 'sale_price' && setSale_price(data[item.id])
        item.id === 'original_price' && setOriginal_price(data[item.id])
        item.id === 'drop_off_time' && setDrop_off_time(data[item.id])

        if (item.id === 'cover') {
          setPreviewSrc(data[item.id])
          setCover(data[item.id])
        }

        if (item.id === 'subject') {
          let _value = [{
            value: data[item.id],
            label: data[item.id]
          }]
          setSubject(_value)
          return {
            ...item,
            value: _value
          }
        } else if (item.id === 'grade') {
          let _value = [{
            value: data[item.id],
            label: 'Grade ' + data[item.id]
          }]
          setGrade(_value)
          return {
            ...item,
            value: _value
          }
        } else if (item.id === 'used_period') {
          let _value = [{
            value: +data[item.id],
            label: +data[item.id],
          }]
          setUsed_period(_value)
          return {
            ...item,
            value: _value
          }
        } else {
          return {
            ...item,
            value: data[item.id]
          }
        }
      })
      setBookInfo(_bookInfo)
    }
    id && loadData()
  }, [])

  useEffect(() => {
    if (!initPage.current) {
      initPage.current = true
    } else {
      judgeForm()
    }
  }, [bookInfo])

  const addImage = async (e) => {
    const file = e.target.files[0];
    console.log(file)
    if (!file) return
    if (file.size > 512 * 1024) {
      window.alert('Please upload jpg/jpeg/png images below 512K')
    } else if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(e.target.value)) {
      window.alert('Please upload images in. png/. jpg/. jpeg format')
    } else {
      setCover(file)
      setFormField('cover', file)
      setHasFile(true)

      var reader = new FileReader();

      reader.addEventListener("load", function () {
        setPreviewSrc(reader.result)
      }, false);

      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };


  const judgeForm = () => {
    let allOk = bookInfo.every(item => {
      return !item.req || (item.req && (!item.tip && item.value))
    })
    return allOk
  }

  const validateForm = fn => {
    let newInfo = bookInfo.map(item => {
      let notice = item.validate(item.value)
      item.tip = notice
      item.id === 'title' && setTitleTip(notice)
      item.id === 'grade' && setGradeTip(notice)
      item.id === 'subject' && setSubjectTip(notice)
      item.id === 'used_period' && setPeriodTip(notice)
      item.id === 'cover' && setCoverTip(notice)
      item.id === 'sale_price' && setSalePriceTip(notice)
      item.id === 'original_price' && setOriginalPriceTip(notice)
      return item
    })

    setBookInfo(newInfo)
    return newInfo
  }

  const setPriceValue = (name, e) => {
    let v = e.target.value
    let _v = v.replace(/[^0-9.]/g, '').replace(/^(\d+)\.(\d\d).*$/, '$1.$2');
    name === 'sale_price' && setSale_price(_v)
    name === 'original_price' && setOriginal_price(_v)
    setFormField(name, _v)
  }

  const setFormField = (name, value) => {
    name === 'subject' && setSubject(value)
    name === 'grade' && setGrade(value)
    name === 'publish_time' && setPublish_time(value)
    name === 'used_period' && setUsed_period(value)

    setBookInfo(info => {
      let _info = info.map(item => {
        if (item.id === name) {
          item.value = value
          let notice = item.validate(value)
          item.tip = notice
          item.id === 'title' && setTitleTip(notice)
          item.id === 'grade' && setGradeTip(notice)
          item.id === 'subject' && setSubjectTip(notice)
          item.id === 'publish_time' && setPublishTimeTip(notice)
          item.id === 'used_period' && setPeriodTip(notice)
          item.id === 'cover' && setCoverTip(notice)
          item.id === 'sale_price' && setSalePriceTip(notice)
          item.id === 'original_price' && setOriginalPriceTip(notice)
        }
        return item
      })
      return _info
    })
  }

  const setForm = e => {
    let { name, value } = e.target
    name === 'title' && setTitle(value)
    name === 'author' && setAuthor(value)
    setBookInfo(info => {
      let _info = info.map(item => {
        if (item.id === name) {
          item.value = value
          let notice = item.validate(value)
          item.tip = notice
          item.id === 'title' && setTitleTip(notice)
          item.id === 'grade' && setGradeTip(notice)
          item.id === 'subject' && setSubjectTip(notice)
          item.id === 'used_period' && setPeriodTip(notice)
          item.id === 'cover' && setCoverTip(notice)
          item.id === 'sale_price' && setSalePriceTip(notice)
          item.id === 'original_price' && setOriginalPriceTip(notice)
        }
        return item
      })
      return _info
    })
  }

  const getParams = () => {
    let params = bookInfo.reduce((base, item) => {
      return {
        ...base,
        [item.id]: item.type === 'select' ? item.value[0].value : item.value,
      }
    }, {})
    return params
  }
  const saveBook = async () => {
    let _bookInfo = validateForm()
    let _allOk = judgeForm(_bookInfo)
    if (!_allOk) {
      return
    }


    let params = getParams()
    let file = null
    if (params.cover && hasFile) {
      file = params.cover
    } else {
      params.cover_url = previewSrc
    }

    delete params.cover
    let url = '/api/book/save'
    if (searchParams.get('id')) {
      params.id = searchParams.get('id')
      url = '/api/book/editBook'
    }
    params.drop_off_time = drop_off_time
    ajax({
      headers: {
        token: localStorage.getItem("__booktoken__"),
      },
      withCredentials: false,
      file: file,
      data: params,
      filename: 'cover',
      action: url,
      onProgress: e => {
      },
      onSuccess: res => {
        console.log(res)
        if (res && res.code) {
          switch (res.code) {
            case 200002:
              setTipMsg('Not editable')
              setOpen(true)
              break;
            default:
              break;
          }
        } else {
          if (res && res.id) {
            history.push('/upload-book-result?id=' + res.id)
          } else if (searchParams.get('id')) {
            history.push('/upload-book-result?id=' + searchParams.get('id'))
          }
        }
      },
      onError: (err, response) => {
        console.log(err, response)
      }
    });

    // let formData = new FormData()
    // for (const key in params) {
    //   if (Object.hasOwnProperty.call(params, key)) {
    //     formData.append(key, params[key])
    //   }
    // }
    // console.log('form', formData)
    // let { data } = await API({
    //   url: '/api/book/save',
    //   method: 'POST',
    //   Headers: {
    //     "Content-Type": "multipart/form-data"
    //   },
    //   data: formData,
    // })
    // console.log(data)
  }
  return (
    <div className={`${pageStyle} upload-book-page`}>
      <div
        style={{
          marginTop: '30px',
          marginLeft: '40px',
        }}
      >
        <ArrowBackRoundedIcon style={{ cursor: 'pointer' }} fontSize="large" color="primary" className={backIcon} onClick={() => history.goBack()} />
      </div>
      <div className={formWrap}>
        <Typography variant="h5">Book Information</Typography>
        <div className={`${formItem} `}>
          <div htmlFor=""><span style={{ color: '#D63232' }}>*</span> Book’s name </div>
          <input
            maxLength="50"
            name="title"
            placeholder=""
            className={`${inputField} ${titleTip ? errorField : ''}`}
            value={title}
            onChange={e => setForm(e)}
          />
          {titleTip ? <div className="form-error">{titleTip}</div> : null}
        </div>

        <div className={formItem}>
          <div htmlFor=""><span style={{ color: '#D63232' }}>*</span> Subject</div>
          <XSelect
            className='books-select-box'
            options={subjectList}
            values={subject}
            placeholder='Please select'
            onChange={(value) => setFormField('subject', value)}
            color='#E0C9B4'
          ></XSelect>
          {subjectTip ? <div className="form-error">{subjectTip}</div> : null}
        </div>

        <div className={formItem}>
          <div htmlFor=""><span style={{ color: '#D63232' }}>*</span>  Grade </div>
          <XSelect
            className='books-select-box'
            options={gradeList}
            values={grade}
            placeholder='Please select'
            onChange={(value) => setFormField('grade', value)}
            color='#E0C9B4'
          ></XSelect>
          {gradeTip ? <div className="form-error">{gradeTip}</div> : null}
        </div>

        <div className={`${formItem} `}>
          <div htmlFor=""> Author Name </div>
          <input
            maxLength="50"
            name="author"
            placeholder=""
            className={inputField}
            value={author}
            onChange={e => setForm(e)}
          />
        </div>

        <div className={`${formItem} picker`}>
          <div htmlFor=""> Publish time </div>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              className={styles.picker}
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              label=""
              value={publish_time}
              onChange={(v) => setFormField('publish_time', v)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </div>

        <div className={formItem}>
          <div htmlFor=""><span style={{ color: '#D63232' }}>*</span>  Using period </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <XSelect
              className='books-select-box'
              options={periodList}
              values={used_period}
              placeholder='Please select'
              onChange={(v) => setFormField('used_period', v)}
              color='#E0C9B4'
            ></XSelect>
            <span className={styles.monthTip}>month</span>
          </div>

          {periodTip ? <div className="form-error">{periodTip}</div> : null}
        </div>

        <div style={{ height: '60px' }}></div>
        <Typography variant="h5">Book’s photograph</Typography>

        <div className={formItem}>
          <div className={styles.uploadBox}>
            <div className={styles.uploadInput}>
              {
                previewSrc ?
                  <>
                    <input
                      accept="image/png, image/jpeg, image/jpg"
                      type="file"
                      style={{ opacity: 0 }}
                      onChange={addImage}
                      className="re-upload re-upload-input"
                    />
                    <div className="re-upload">Re-upload</div>
                  </>
                  :
                  <>
                    <input
                      accept="image/png, image/jpeg, image/jpg"
                      id="contained-button-file"
                      type="file"
                      style={{ display: 'none' }}
                      onChange={addImage}
                    />

                    <label htmlFor="contained-button-file" style={{ cursor: 'pointer' }}>
                      <div className={styles.uploadIcon} aria-label="upload picture">
                        <img src={UploadIcon} alt="" width="34" height="34" style={{ objectFit: 'contain' }} />
                        <p>Upload image</p>
                      </div>
                    </label>
                  </>
              }
            </div>
            {previewSrc ? <img src={previewSrc} alt="" className={styles.previewImg} /> : null}
          </div>
          <div className={`${coverTip ? 'form-error' : ''}`} style={{
            fontSize: '15px',
            fontFamily: 'Helvetica',
            color: '#999',
            lineHeight: '20px',
            marginTop: '10px',
          }}>Please upload jpg/jpeg/png images below 512K</div>
        </div>

        <div style={{ height: '60px' }}></div>
        <Typography variant="h5">Set the price</Typography>

        <div className={`${formItem} `}>
          <div htmlFor=""><span style={{ color: '#D63232' }}>*</span> Market Price </div>
          <input
            maxLength="8"
            name="original_price"
            placeholder=""
            value={original_price}
            className={`${inputField} ${originalPriceTip ? errorField : ''}`}
            onChange={e => setPriceValue('original_price', e)}
          />
          {originalPriceTip ? <div className="form-error">{originalPriceTip}</div> : null}


        </div>
        <div className={`${formItem} `}>
          <div htmlFor=""><span style={{ color: '#D63232' }}>*</span> Your Price </div>
          <input
            maxLength="8"
            name="sale_price"
            placeholder=""
            value={sale_price}
            className={`${inputField} ${salePriceTip ? errorField : ''}`}
            onChange={e => setPriceValue('sale_price', e)}
          />
          {salePriceTip ? <div className="form-error">{salePriceTip}</div> : null}
        </div>

        <div style={{ height: '60px' }}></div>
        <Typography variant="h5">Drop Off Time</Typography>

        <div className={`${formItem} ${styles.inputField} ${drop_off_time === 'Monday 12:30-13:00' ? styles.active : ''}`} onClick={() => setDrop_off_time('Monday 12:30-13:00')}>
          <span>Monday 12:30-13:00</span>
          {drop_off_time === 'Monday 12:30-13:00' ? <img className={styles.chooseIcon3} src={ChooseIcon} alt="" /> : null}
        </div>
        <div className={`${formItem} ${styles.inputField} ${drop_off_time === 'Wednesday 12:30-13:00' ? styles.active : ''}`} onClick={() => setDrop_off_time('Wednesday 12:30-13:00')}>
          <span>Wednesday 12:30-13:00</span>
          {drop_off_time === 'Wednesday 12:30-13:00' ? <img className={styles.chooseIcon3} src={ChooseIcon} alt="" /> : null}
        </div>
        <div
          className={`${formItem} ${styles.inputField} ${drop_off_time === 'Friday 12:30-13:00' ? styles.active : ''}`}
          onClick={() => setDrop_off_time('Friday 12:30-13:00')}
        >
          <span>Friday 12:30-13:00</span>
          {
            drop_off_time === 'Friday 12:30-13:00' ?
              <img className={styles.chooseIcon3} src={ChooseIcon} alt="" />
              : null
          }
        </div>

        <div className={submitBtn} onClick={saveBook}>
          Submit Request
        </div>
      </div>
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        anchorPosition={{
          top: 100,
        }}
        classes={{
          root: 'msgTip',
        }}
      >
        {tipMsg}
      </Popover>
    </div>
  )
}

export default Auth
