import React, { useState, useEffect, useUpdateEffect, useRef } from 'react'
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded'
import { useDispatch, useSelector } from 'react-redux'
import { SET_NAVBAR_SHOW, SET_NAVBAR_HIDE, GET_PROFILE } from '../../constants/actions.js'
import { Typography } from '@material-ui/core'
import useStyles from './styles.js'
import { Link } from 'react-router-dom'
import api from '../../api'
import { useHistory } from 'react-router-dom'

const initialState = {
  firstName: '',
  lastName: '',
  college: '',
  location: '',
  email: '',
  password: '',
  confirmPassword: '',
}
const emailReg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
const pwdReg = /^(?=.*[a-zA-Z]+)(?=.*[0-9]+)[a-zA-Z0-9]{6,20}$/

const Auth = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [usernameTip, setUsernameTip] = useState('')
  const [emailTip, setEmailTip] = useState('')
  const [studentTip, setStudentTip] = useState('');
  const [student_no, setStudent_no] = useState('');
  const [pwdTip, setPwdTip] = useState('')
  const [rePwdTip, setRePwdTip] = useState('')
  const [codeTip, setCodeTip] = useState('')
  const [allOk, setAllOk] = useState(false)
  const [code, setCode] = useState(null)
  const [current, setCurrent] = useState(1)
  const [ajaxing, setAjaxing] = useState(false)
  const { pageStyle, backIcon, formWrap, formItem, inputField, errorField, submitBtn, registerBtn, signInBtn } = useStyles()
  const initPage = useRef(false)

  const [registerInfo, setRegisterInfo] = useState([
    {
      id: 'username',
      value: '',
      tip: '',
      validate: v => {
        if (!v) {
          return 'Enter your name'
        }
        return ''
      },
    },
    {
      id: 'email',
      value: '',
      tip: '',
      validate: email => {
        if (!email) {
          return 'Enter your email'
        } else if (!emailReg.test(email)) {
          return 'Wrong or Invalid email'
        } else {
          return ''
        }
      },
    },
    {
      id: 'student_no',
      value: '',
      tip: '',
      validate: student => {
        if (!student) {
          return 'Please enter you student number'
        } else {
          return ''
        }
      },
    },
    {
      id: 'password',
      value: '',
      tip: '',
      validate: password => {
        if (!password || !pwdReg.test(password)) {
          return 'At least 6 characters containing letters and numbers are required'
        } else {
          return ''
        }
      },
    },
    {
      id: 'repassword',
      value: '',
      tip: '',
      validate: repassword => {
        let pwd = (registerInfo.find(item => item.id === 'password') || {}).value
        if (!repassword || repassword !== pwd) {
          return 'Passwords must match'
        }
        return ''
      },
    },
  ])

  useEffect(() => {
    dispatch({
      type: SET_NAVBAR_HIDE,
      payload: {
        navbar: 'hide',
      },
    })
    return () => {
      dispatch({
        type: SET_NAVBAR_SHOW,
        payload: {
          navbar: 'show',
        },
      })
    }
  }, [])

  useEffect(() => {
    if (!initPage.current) {
      initPage.current = true
    } else {
      judgeForm()
    }
  }, [registerInfo])

  const judgeForm = () => {
    let allOk = registerInfo.every(item => {
      return !item.tip && item.value
    })
    setAllOk(allOk)
    return allOk
  }

  const validateForm = fn => {
    let newInfo = registerInfo.map(item => {
      let notice = item.validate(item.value)
      item.tip = notice
      item.id === 'username' && setUsernameTip(notice)
      item.id === 'email' && setEmailTip(notice)
      item.id === 'student_no' && setStudentTip(notice)
      item.id === 'password' && setPwdTip(notice)
      item.id === 'repassword' && setRePwdTip(notice)
      return item
    })

    setRegisterInfo(newInfo)
    return newInfo
  }

  const verifyEmail = async () => {
    let newInfo = validateForm()
    let _allOk = judgeForm(newInfo)
    if (!_allOk) {
      return
    }
    let params = getParams()
    if (ajaxing) {
      return
    }
    setAjaxing(true)
    let { data } = await api.verifyEmail(params)
    if (data && data.code) {
      switch (data.code) {
        case 100007:
          setUsernameTip('Your name is already in use')
          break
        case 100006:
          setEmailTip("There's already an account with this email")
          break
        default:
          break
      }
    }
    if (!data || !data.code) {
      setCurrent(2)
    }
    setAjaxing(false)
  }

  const setForm = e => {
    let { name, value } = e.target
    let _value = value
    if (name === 'student_no') {
      let v = value.replace(/[^0-9]+/, '')
      _value = v
      setStudent_no(v)
    }
    setRegisterInfo(info => {
      let _info = info.map(item => {
        if (item.id === name) {
          item.value = _value
          let notice = item.validate(_value)
          item.tip = notice
          name === 'username' && setUsernameTip(notice)
          name === 'email' && setEmailTip(notice)
          name === 'student_no' && setStudentTip(notice)
          name === 'password' && setPwdTip(notice)
          name === 'repassword' && setRePwdTip(notice)
        }
        return item
      })
      return _info
    })
  }
  const getEmail = () => {
    return (registerInfo.find(item => item.id === 'email') || {}).value
  }
  const getParams = () => {
    let params = registerInfo.reduce((base, item) => {
      return {
        ...base,
        [item.id]: item.value,
      }
    }, {})
    return params
  }
  const createAccount = async () => {
    let params = getParams()
    if (ajaxing) return
    setAjaxing(true)
    let { data } = await api.register({
      ...params,
      code,
    })
    setAjaxing(false)
    if (data && !data.code) {
      localStorage.setItem('profile', JSON.stringify(data))
      localStorage.setItem('__booktoken__', data.token)
      dispatch({
        type: GET_PROFILE,
        payload: data,
      })
      history.push('/home')
    } else {
      if (data && data.code) {
        switch (data.code) {
          case 100008:
            setCodeTip('Your OTP is incorrect')
            break
          default:
            break
        }
      }
    }
  }
  return (
    <div className={pageStyle}>
      {current === 1 ? (
        <>
          <div
            style={{
              marginTop: '30px',
              marginLeft: '40px',
            }}
          >
            <ArrowBackRoundedIcon style={{ cursor: 'pointer' }} fontSize="large" color="primary" className={backIcon} onClick={() => history.goBack()} />
          </div>
          <div className={formWrap}>
            <Typography variant="h5">Create account</Typography>
            <div className={`${formItem} `}>
              <div htmlFor="">User Name</div>
              <input
                maxLength="50"
                name="username"
                placeholder="username"
                className={`${inputField} ${usernameTip ? errorField : ''}`}
                onChange={e => setForm(e)}
              />
              {usernameTip ? <div className="form-error">{usernameTip}</div> : null}
            </div>

            <div className={formItem}>
              <div htmlFor="">Email</div>
              <input
                maxLength="50"
                name="email"
                className={`${inputField} ${emailTip ? errorField : ''}`}
                placeholder="email"
                onChange={e => setForm(e)}
              />
              {emailTip ? <div className="form-error">{emailTip}</div> : null}
            </div>

            <div className={formItem}>
              <div htmlFor="">Student Number</div>
              <input
                maxLength="10"
                name="student_no"
                value={student_no}
                className={`${inputField} ${studentTip ? errorField : ''}`}
                placeholder="Student Number"
                onChange={e => setForm(e)}
              />
              {studentTip ? <div className="form-error">{studentTip}</div> : null}
            </div>

            <div className={formItem}>
              <div htmlFor="">Password</div>
              <input
                maxLength="20"
                name="password"
                type="password"
                className={`${inputField} ${pwdTip ? errorField : ''}`}
                placeholder="At least 6 characters"
                onChange={e => setForm(e)}
              />
              {pwdTip ? <div className="form-error">{pwdTip}</div> : null}
            </div>
            <div className={formItem}>
              <div htmlFor="">Password</div>
              <input
                maxLength="20"
                name="repassword"
                type="password"
                className={`${inputField} ${rePwdTip ? errorField : ''}`}
                placeholder="At least 6 characters"
                onChange={e => setForm(e)}
              />
              {rePwdTip ? <div className="form-error">{rePwdTip}</div> : null}
            </div>

            <div className={submitBtn} onClick={verifyEmail}>
              {allOk ? 'Verify email' : 'Continue'}
            </div>

            <div className={registerBtn}>
              <span>Already have an account? </span>
              <span className={signInBtn} onClick={() => history.push('/login')}>
                Sign in
              </span>
            </div>
          </div>
        </>
      ) : null}
      {current === 2 ? (
        <>
          <div
            style={{
              marginTop: '30px',
              marginLeft: '40px',
            }}
          >
            <ArrowBackRoundedIcon style={{ cursor: 'pointer' }} fontSize="large" color="primary" className={backIcon} onClick={() => setCurrent(1)} />
          </div>
          <div className={formWrap}>
            <Typography variant="h5" className="text-bold">
              Verify email address
            </Typography>
            <Typography style={{ marginTop: '20px' }}> To verify your email, we've sent a One Time Password (OTP) to {getEmail()}</Typography>

            <div className={formItem}>
              <div htmlFor="">Enter OTP</div>
              <input name="code" type="text" className={`${inputField}`} placeholder="" onChange={e => setCode(e.target.value)} />
              {codeTip ? <div className="form-error">{codeTip}</div> : null}
            </div>
            <div className={submitBtn} onClick={createAccount}>
              Create your account
            </div>
            <div className={registerBtn}>
              <span>Already have an account? </span>
              <span className={signInBtn} onClick={() => history.push('/login')}>Sign in</span>
            </div>
          </div>
        </>
      ) : null}
    </div>
  )
}

export default Auth
