import React, { useState, useEffect, useRef } from 'react'
import Select from 'react-dropdown-select'
import ArrowDown from '../../images/arrow-down-filling.png'
import ArrowUp from '../../images/arrow-up-filling.png'
import './styles.css'

const XSelect = (props) => {
  const { className = 'books-select-box', options, values, onChange, placeholder = 'select', color = '#E0C9B4' } = props

  console.log('vvvv', values)
  const [valueInfo, setValueInfo] = useState(values);
  const setValue = (v) => {
    
    setValueInfo(v)
    onChange && onChange(v)
  }

  useEffect(() => {
    setValueInfo(values)
  }, [values]);
  return (
    <div style={{ width: '100%' }}>
      <Select
        className={className}
        options={options}
        values={valueInfo}
        placeholder={placeholder}
        onChange={(value) => setValue(value)}
        color={color}
        dropdownHandleRenderer={({ props, state, methods }) => {
          return state.dropdown ?
            <img className="arrow" src={ArrowUp} alt="" /> :
            <img src={ArrowDown} className="arrow" alt="" />
        }}
      ></Select>
    </div>
  )
}

export default XSelect;