import React, { useState, useEffect, useRef, useCallback } from 'react'
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded'
import { useDispatch, useSelector } from 'react-redux'
import { SET_NAVBAR_SHOW, SET_NAVBAR_HIDE, GET_PROFILE } from '../../constants/actions.js'
import { Typography, Popover, ClickAwayListener, Grid, Pagination } from '@material-ui/core'
import Select from 'react-dropdown-select'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import useStyles from './styles.js'
import { Link } from 'react-router-dom'
import api from '../../api'
import { useHistory } from 'react-router-dom'
import { subjectListAll, gradeListAll } from '../../baseData.js'
import './style.css'
import ArrowDown from '../../images/arrow-down-filling.png'
import ArrowUp from '../../images/arrow-up-filling.png'
import SerachIcon from '../../images/search.png'
import ReactPaginate from 'react-paginate';
import SearchEmptyIcon from '../../images/search-empty-icon.png'

const Auth = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const styles = useStyles()
  const searchParams = new URLSearchParams(window.location.search);
  const _grade = searchParams.get('grade');
  const _subject = searchParams.get('subject');
  const [books, setBooks] = useState([]);
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageOffset, setPageOffset] = useState(0);
  const [title, setTitle] = useState('');

  console.log('sub', _subject)
  const [subject, setSubject] = useState(_subject ? [{
    value: _subject,
    label: _subject.toLowerCase(),
  }] : [{
    value: 'all',
    label: 'all',
  }]);
  const [grade, setGrade] = useState(_grade ? [{
    value: _grade,
    label: 'Grade ' + _grade,
  }] : [{
    value: 'all',
    label: 'all',
  }]);

  const getBooks = useCallback(
    async () => {
      let sub = subject[0].value || ''
      let gra = grade[0].value || ''
      let { data } = await api.getBooks({
        title: title,
        id: '',
        grade: gra === 'all' ? '' : gra,
        subject: sub === 'all' ? '' : sub,
        limit: 10,
        offset: 10 * pageOffset,
      })
      if (data && !data.code) {
        setBooks(data.rows || [])
        setCount(Math.ceil(data.count / 10))
      }
    },
    [grade, subject, pageOffset],
  )


  // const getBooks = async (page = 0) => {
  //   let { data } = await api.getBooks({
  //     search: {
  //       title: title,
  //       id: '',
  //       grade: _grade || '',
  //       subject: _subject || '',
  //     },
  //     limit: 10,
  //     offset: 10 * page,
  //   })
  //   if (data && !data.code) {
  //     setBooks(data.rows || [])
  //     setCount(Math.ceil(data.count / 10))
  //   }
  // }

  useEffect(() => {
    getBooks()
  }, [subject, grade, pageOffset, getBooks]);

  const handlePageChange = (event) => {
    setPageOffset(event.selected);
  }

  const searchBook = () => {
    setSubject([{
      value: 'all',
      label: 'all',
    }])
    setGrade([{
      value: 'all',
      label: 'all',
    }])
  }

  const goDtail = (item) => {
    history.push('/book/' + item.id)
  }


  return (
    <div className={styles.pageStyle}>
      <div className="books-search">
        <div className={`${styles.formItem} form-item`}>
          <label className="label">Subject</label>
          <div style={{
            width: '170px', marginLeft: '10px',
          }}>
            <Select
              className='books-select-box'
              options={subjectListAll}
              values={subject}
              placeholder={'select...'}
              onChange={(value) => setSubject(value)}
              color={'#E0C9B4'}
              dropdownHandleRenderer={({ props, state, methods }) => {
                return state.dropdown ?
                  <img className="arrow" src={ArrowUp} alt="" /> :
                  <img src={ArrowDown} className="arrow" alt="" />
              }}
            ></Select>
          </div>
        </div>

        <div className={`${styles.formItem} form-item`}>
          <label className="label">Grade</label>
          <div style={{
            width: '170px', marginLeft: '10px',
          }}>
            <Select
              className='books-select-box'
              options={gradeListAll}
              values={grade}
              placeholder={'select...'}
              onChange={(value) => setGrade(value)}
              color={'#E0C9B4'}
              dropdownHandleRenderer={({ props, state, methods }) => {
                return state.dropdown ?
                  <img className="arrow" src={ArrowUp} alt="" /> :
                  <img src={ArrowDown} className="arrow" alt="" />
              }}
            ></Select>
          </div>
        </div>
        <div style={{ flex: 1 }}></div>
        <div className="search-input-box">
          <input type="text" placeholder="Search" onChange={(e) => setTitle(e.target.value)} />
          <span onClick={searchBook}>
            <img src={SerachIcon} alt="" className="search-icon" />
          </span>
        </div>
      </div>
      <div className="books-wrap">
        <div className="books-list">
          {
            books.length > 0 ? books.map(book => {
              return <div className="item" key={book.id} onClick={() => goDtail(book)}>
                <img src={book.cover} alt="cover" />
                <p className="p1 ellipsis">${book.sale_price}</p>
                <p className="p2 ellipsis">{book.title}</p>
                {
                  book.author ?
                    <p className="p3">{book.author}</p>
                    : <p className="p3 ellipsis"></p>
                }
                <p className="p3 ellipsis">{book.subject} | {'Grade ' + book.grade}</p>
              </div>
            }) : <div className="search-empty-box">
              <img src={SearchEmptyIcon} alt="" />
              <p>No results </p>
            </div>
          }
        </div>
      </div>
      {books.length > 0 ? <div className="page-wrap">
        <div style={{ flex: 1 }}></div>
        <ReactPaginate
          previousLabel="Previous"
          nextLabel="Next"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          pageCount={count}
          marginPagesDisplayed={2}
          pageRangeDisplayed={2}
          onPageChange={handlePageChange}
          containerClassName="pagination"
          activeClassName="active"
          forcePage={pageOffset}
        />
      </div> : null}

    </div>
  )
}

export default Auth
