import React from "react";
import useStyles from "./styles.js";
import { useHistory } from "react-router";
import useJudgeLogin from "../../../customUse/useJudgeLogin.js";

const Description = () => {
    const history = useHistory()
    const classes = useStyles();
    let isLogin = useJudgeLogin()

    function handleClick() {
        if (isLogin) {
            history.push(`/selecttodo`);
        } else {
            history.push('/login')
        }
    }

    return (
        <div className={classes.parent}>
            <div
                className={classes.paper}
                style={{
                    background: "rgba(71,43,145,1)",
                }}
            >
                <div style={{
                    color: "white",
                    fontFamily: "Hei",
                    fontSize: "88px",
                    paddingTop: '80px',
                    fontWeight: "bolder",
                    marginLeft: "100px"
                }}>
                    Appleby Online Bookstore
                </div>
                <div style={{
                    color: "#E0C9B4",
                    fontFamily: "Hei",
                    fontSize: "28px",
                    marginTop: "115px",
                    marginLeft: "100px"
                }}>
                    An Appleby student personized platform
                </div>
                <div style={{
                    width: "190px",
                    height: "67px",
                    color: "rgba(37,30,78,1)",
                    fontFamily: "Hei",
                    fontWeight: "bolder",
                    display: "flex",
                    fontSize: "25px",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "6px",
                    marginTop: "55px",
                    marginLeft: "100px",
                    background: "#E5A477",
                    boxSizing: "border-box",
                    border: "1px solid #979797",
                    cursor: 'pointer',
                }} onClick={() => handleClick()}>
                    Get Started
                </div>


                {/*<div className={classes.content}>*/}
                {/*  <Typography variant="h1">*/}
                {/*    <div className={classes.text}> {props.item.heading}</div>*/}
                {/*    <Box*/}
                {/*      fontWeight="fontWeightBold"*/}
                {/*      letterSpacing={10}*/}
                {/*      className={classes.contentBold}*/}
                {/*    >*/}
                {/*      BookXchanger*/}
                {/*    </Box>*/}
                {/*  </Typography>*/}
                {/*  <Typography variant="h5" className={classes.smallText}>*/}
                {/*    {props.item.subHeading}*/}
                {/*  </Typography>*/}
                {/*  <Typography variant="h6" className={classes.smallText}>*/}
                {/*    {props.item.description}*/}
                {/*  </Typography>*/}
                {/*</div>*/}
            </div>
        </div>
    );
};

export default Description;
