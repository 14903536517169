import React, { useState, useEffect } from 'react'
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded'
import { useDispatch, useSelector } from 'react-redux'
import { SET_NAVBAR_SHOW, SET_NAVBAR_HIDE } from '../../constants/actions.js'
import ChooseIcon from '../../images/choose-icon.png'
import NextIcon from '../../images/next-icon.png'

import useStyles from './styles.js'
import { useHistory, useLocation } from 'react-router-dom'

const SelectGrade = () => {
    const location = useLocation();
    const history = useHistory()
    const dispatch = useDispatch()
    const styles = useStyles()
    const [user, setUser] = useState({})
    const [chooseType, setChooseType] = useState('7');
    const searchParams = new URLSearchParams(window.location.search);
    const type = searchParams.get('type');

    const [grades, setGrades] = useState([
        {
            id: '7',
            name: 'Grade 7',
            checked: false,
        },
        {
            id: '8',
            name: 'Grade 8',
            checked: false,
        },
        {
            id: '9',
            name: 'Grade 9',
            checked: false,
        },
        {
            id: '10',
            name: 'Grade 10',
            checked: false,
        },
        {
            id: '11',
            name: 'Grade 11',
            checked: false,
        },
        {
            id: '12',
            name: 'Grade 12',
            checked: false,
        },
    ]);
    useEffect(() => {
        dispatch({
            type: SET_NAVBAR_HIDE,
            payload: {
                navbar: 'hide',
            },
        })
        return () => {
            dispatch({
                type: SET_NAVBAR_SHOW,
                payload: {
                    navbar: 'show',
                },
            })
        }
    }, [dispatch])

    useEffect(() => {
        let _user = JSON.parse(localStorage.getItem('profile'))
        if (_user?.token) {
            setUser(_user)
        } else {
            history.push('/login')
        }
    }, [user?.token, history])

    const goNext = () => {
        history.push(`/selectsubject?grade=${chooseType}`)
    }

    return (
        <div className={styles.pageStyle}>
            <div
                style={{
                    paddingTop: '30px',
                    marginLeft: '40px',
                }}
            >
                <ArrowBackRoundedIcon style={{ cursor: 'pointer' }} fontSize="large" color="primary" className={styles.backIcon} onClick={() => history.goBack()} />
            </div>
            <div style={{ textAlign: 'center' }}>
                <h3 style={{ marginTop: '60px' }}>What grade are you?</h3>
                <p>Please select your grade. (Required)</p>

                <div className={styles.selBox2}>
                    {
                        grades.length > 0 ? grades.map(item => {
                            return <div key={item.id} className={`${styles.chooseDiv2} ${chooseType === item.id ? styles.active : ''}`} style={{ marginRight: '16px' }} onClick={() => setChooseType(item.id)}>
                                <p>{item.name}</p>
                                {chooseType === item.id ? <img className={styles.chooseIcon2} src={ChooseIcon} alt="" /> : null}
                            </div>
                        }) : null
                    }
                </div>
                {chooseType ? <img className={styles.nextIcon} src={NextIcon} alt="" onClick={goNext} /> : null}
            </div>

        </div>
    )
}

export default SelectGrade
