import React, { useState, useEffect } from 'react'
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded'
import { useDispatch, useSelector } from 'react-redux'
import { SET_NAVBAR_SHOW, SET_NAVBAR_HIDE } from '../../constants/actions.js'
import ChooseIcon from '../../images/choose-icon.png'
import NextIcon from '../../images/next-icon.png'

import useStyles from './styles.js'
import { useHistory, useLocation } from 'react-router-dom'
import { subjectList } from '../../baseData.js'
const SelectGrade = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const styles = useStyles()
    const [user, setUser] = useState({})
    const [chooseType, setChooseType] = useState('English');
    const searchParams = new URLSearchParams(window.location.search);
    const grade = searchParams.get('grade');
    
    useEffect(() => {
        dispatch({
            type: SET_NAVBAR_HIDE,
            payload: {
                navbar: 'hide',
            },
        })
        return () => {
            dispatch({
                type: SET_NAVBAR_SHOW,
                payload: {
                    navbar: 'show',
                },
            })
        }
    }, [dispatch])

    useEffect(() => {
        let _user = JSON.parse(localStorage.getItem('profile'))
        if (_user?.token) {
            setUser(_user)
        } else {
            history.push('/login')
        }
    }, [user?.token, history])

    const goNext = () => {
        history.push(`/shop?grade=${grade}&subject=${chooseType}`)
    }

    return (
        <div className={styles.pageStyle}>
            <div
                style={{
                    paddingTop: '30px',
                    marginLeft: '40px',
                }}
            >
                <ArrowBackRoundedIcon style={{ cursor: 'pointer' }} fontSize="large" color="primary" className={styles.backIcon} onClick={() => history.goBack()} />
            </div>
            <div style={{ textAlign: 'center' }}>
                <h3 style={{ marginTop: '60px' }}>What subject you want to pick?</h3>
                <p>Select all that applied. (Required)</p>

                <div className={styles.selBox3}>
                    {
                        subjectList.length > 0 ? subjectList.map(item => {
                            return <div key={item.value} className={`${styles.chooseDiv3} ${chooseType === item.value ? styles.active : ''}`} style={{ marginRight: '16px' }} onClick={() => setChooseType(item.value)}>
                                <p>{item.label}</p>
                                {chooseType === item.value ? <img className={styles.chooseIcon3} src={ChooseIcon} alt="" /> : null}
                            </div>
                        }) : null
                    }
                </div>
                {chooseType ? <img className={styles.nextIcon} src={NextIcon} alt="" onClick={goNext} /> : null}
            </div>

        </div>
    )
}

export default SelectGrade
