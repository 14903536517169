import { makeStyles } from "@material-ui/core";

const styles = makeStyles((theme) => ({
  pageStyle: {
    fontFamily: 'Helvetica-Bold, Helvetica',
    width: '100%',
    minHeight: '100%',
    background: '#F4F4F4',
    border: '1px solid #F9F6EB',
    color: '#261C45',
    padding: '102px 30px 20px',
  },
  pageDetailStyle: {
    background: '#fff',
  },
  formItem: {
    display: 'flex',
    alignItems: 'center',
    width: '300px',
  },
  selectName: {
    verticalAlign: 'baseline',
    svg: {
      verticalAlign: 'baseline',
    }
  },
  bookInfo: {
    display: 'flex',
    padding: '10px',
    fontFamily: 'Helvetica-Bold, Helvetica',
  },
  mainText: {
    fontWeight: 'bold',
    fontSize: '18px',
    marginBottom: '16px !important',
  },
  mgt20: {
    marginTop: '20px',
  },
  inputField: {
    marginTop: '15px',
    width: '672px',
    height: '42px',
    background: '#FFFFFF',
    borderRadius: '4px',
    border: '1px solid #D9D9D9',
    padding: '10px 13px',
    fontFamily: 'PingFangSC-Regular, PingFang SC',
    position: 'relative',
    '&:focus-visible': {
      outline: 'none',
      border: '1px solid #e0c9b4',
    }
  },
  active: {
    border: '1px solid #e0c9b4',
  },
  chooseIcon3: {
    position: 'absolute',
    right: '8px',
    bottom: '10px',
    width: '18px',
    height: '18px',
  },
}));

export default styles;