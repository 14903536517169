import React, { useState, useEffect, useCallback, useRef } from 'react'
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded'
import { useDispatch, useSelector } from 'react-redux'
import { SET_NAVBAR_SHOW, SET_NAVBAR_HIDE } from '../../constants/actions.js'
import { Grid, Typography, Button } from '@material-ui/core'
import useStyles from './styles.js'
import { Link } from 'react-router-dom'
import api, { bookOrder } from '../../api'
import { useHistory } from 'react-router-dom'
import XSelect from '../../comps/select/Select'
import SerachIcon from '../../images/search.png'
import ReactPaginate from 'react-paginate';
import './style.css'
import SearchEmptyIcon from '../../images/search-empty-icon.png'
import ClearIcon from '../../images/clear-icon.png'
import moment from 'moment'
import ConfrimModal from '../../comps/config-modal/ConfirmModal'


const stateMap = {
  1: 'Ordered',
  2: 'Closed',
  3: 'Completed',
}

const statusList = [
  {
    value: 'all',
    label: 'all'
  },
  {
    value: 1,
    label: 'Ordered'
  },
  {
    value: 2,
    label: 'Closed'
  },
  {
    value: 3,
    label: 'Completed'
  }
]
const Auth = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const styles = useStyles()
  const [orders, setOrders] = useState([]);
  const [title, setTitle] = useState('');
  const [status, setStatus] = useState([{ value: 'all', label: 'all' }]);
  const [count, setCount] = useState(0);
  const [pageOffset, setPageOffset] = useState(0);
  const user = useSelector(state => state.user)
  const [addModal, setAddModal] = useState(false);
  const [curBook, setCurBook] = useState({});
  const [curOrder, setCurOrder] = useState({});

  const getOrders = useCallback(
    async () => {
      let statusValue = status[0].value
      let { data } = await api.getMyOrders({
        book_title: title,
        state: statusValue === 'all' ? '' : statusValue,
        id: '',
        limit: 10,
        offset: 10 * pageOffset,
      })
      if (data && !data.code) {
        setOrders(data.rows || [])
        setCount(Math.ceil(data.count / 10))
      }
    },
    [status, pageOffset],
  )

  useEffect(() => {
    getOrders()
  }, [status, pageOffset, getOrders]);


  const searchBook = () => {
    setPageOffset(0)
    setStatus([{
      value: 'all',
      label: 'all',
    }])
  }

  const handlePageChange = (event) => {
    setPageOffset(event.selected);
  }
  const finish = async (order, book) => {
    let { data } = await api.finishOrder({
      id: order.id,
      book_id: book.book_id
    })
    getOrders()
  }

  const cancelModal = (order, book) => {
    setCurBook(book)
    setCurOrder(order)
    setAddModal(true)
    // let { data } = await api.cancelOrder({
    //   id: order.id,
    //   book_id: book.book_id
    // })
    // getOrders()
  }
  const cancelOrder = async (order, book) => {
    await api.cancelOrder({
      id: curOrder.id,
      book_id: curBook.book_id
    })
    setAddModal(false)
    getOrders()
  }

  const exportData = async () => {
    let statusValue = status[0].value

    window.open(
      `${window.location.origin}/api/admin/exportOrders?book_title=${title}&state=${statusValue === 'all' ? '' : statusValue}`,
      '_blank'
    )
  }

  return (
    <div className={`${styles.pageStyle} my-orders`} >
      <div className="my-books-search">
        <div>Orders</div>
        <div style={{ flex: 1 }}>
          {
            user.role === 10 ? <span className="export-btn" onClick={exportData}>
              Export order
            </span> : null
          }
        </div>
        <div className={`${styles.formItem} form-item`}>
          <label className="label">Status</label>
          <div style={{
            width: '170px', marginLeft: '10px',
          }}>
            <XSelect
              className='books-select-box'
              options={statusList}
              values={status}
              placeholder='Please select'
              onChange={(value) => setStatus(value)}
              color='#E0C9B4'
            ></XSelect>
          </div>
        </div>
        <div className="search-input-box">
          <input value={title} type="text" placeholder="Search" maxLength={30} onChange={(e) => setTitle(e.target.value)} />
          {
            title ? <img src={ClearIcon} alt="" className="clear-icon" onClick={() => setTitle('')} /> : null
          }
          <span onClick={searchBook}>
            <img src={SerachIcon} alt="" className="search-icon" />
          </span>
        </div>
      </div>

      <Grid container spacing={1} className={styles.totalInfo}>
        <Grid item xs={5}>
          <Typography style={{ fontWeight: 'bold' }}>
            Book Information
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography style={{ fontWeight: 'bold' }}>
            Price
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography style={{ fontWeight: 'bold' }}>
            Quantity
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography style={{ fontWeight: 'bold' }}>
            Subtotal
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography style={{ fontWeight: 'bold' }}>
            Status
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography style={{ fontWeight: 'bold' }}>
            Operation
          </Typography>
        </Grid>
      </Grid>

      {
        orders.length > 0 ?
          orders.map(order => {
            return <>
              <div className="order-info">
                <div className="order-item">
                  <span style={{ fontWeight: 'bold' }}>Order Time: </span>
                  <span>{moment(new Date(order.create_time)).format('YYYY-MM-DD HH:mm:ss')}</span>
                </div>
                <div className="order-item">
                  <span style={{ fontWeight: 'bold' }}>Order No: </span>
                  <span>{order.order_no}</span>
                </div>
                <div className="order-item">
                  <span style={{ fontWeight: 'bold' }}>Pick Up Time: </span>
                  <span>{order.pick_up_time}</span>
                </div>

                <div className="order-item">
                  <span style={{ fontWeight: 'bold' }}>User Information: </span>
                  <span>{order.buyer_username}, {order.buyer_email}, {order.student_no}</span>
                </div>
              </div>

              {
                (order.books || []).map(book => {
                  return <Grid container spacing={1} className="card-list-body" key={book.id}>
                    <Grid item xs={5} className="book-info">
                      <img src={book.cover} alt="" className="cover" />
                      <div className="title">{book.title}</div>
                    </Grid>
                    <Grid item xs={1}>
                      <div>${book.price}</div>
                    </Grid>
                    <Grid item xs={1}>
                      1
                    </Grid>
                    <Grid item xs={1}>
                      <div>${book.price}</div>
                    </Grid>
                    <Grid item xs={1}>
                      <div>{stateMap[book.state]}</div>
                    </Grid>
                    <Grid item xs={3}>
                      {
                        user.role === 10 ?
                          <div className="btn-operate">
                            {
                              book.state === 1 ?
                                <>
                                  <span onClick={() => finish(order, book)} className="upload">
                                    Finish
                                  </span>
                                  <span className="su">|</span>
                                  <span onClick={() => cancelModal(order, book)} className="del">
                                    Cancel
                                  </span>
                                </> : '-'
                            }
                          </div>
                          : null
                      }
                    </Grid>
                  </Grid>
                })
              }
            </>
          })
          : <div className="search-empty-box">
            <img src={SearchEmptyIcon} alt="" />
            <p>No results </p>
          </div>
      }

      {
        orders.length > 0 ?
          <div className="page-wrap">
            <div style={{ flex: 1 }}></div>
            <ReactPaginate
              previousLabel="Previous"
              nextLabel="Next"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              pageCount={count}
              marginPagesDisplayed={2}
              pageRangeDisplayed={2}
              onPageChange={handlePageChange}
              containerClassName="pagination"
              activeClassName="active"
              forcePage={pageOffset}
            />
          </div>
          : null
      }
      {
        addModal ?
          <ConfrimModal
            msg="Are you sure you want to cancel it?"
            confirmText="Cancel"
            onConfirm={cancelOrder}
            onClose={() => setAddModal(false)}
          ></ConfrimModal>
          : null
      }
    </div >
  )
}

export default Auth
