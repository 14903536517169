import React, { useState, useEffect, useRef, useCallback } from 'react'
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded'
import { useDispatch, useSelector } from 'react-redux'
import { SET_NAVBAR_SHOW, SET_NAVBAR_HIDE, GET_PROFILE } from '../../constants/actions.js'
import { Typography, Popover, ClickAwayListener, Grid, Pagination } from '@material-ui/core'
import useStyles from './styles.js'
import api from '../../api/index'
import { useHistory, useParams } from 'react-router-dom'
import './style.css'
import ConfrimModal from '../../comps/config-modal/ConfirmModal'
import { ADD_CARD } from '../../constants/actions'
import moment from 'moment'

const Auth = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const styles = useStyles()
  const [book, setBook] = useState({});
  const user = useSelector(state => state.user)
  const card = useSelector(state => state.card)
  const params = useParams();
  const [addModal, setAddModal] = useState(false);
  console.log(params)

  const getBook = async () => {
    let { data } = await api.getBook({
      id: params.bookId
    })
    if (data && !data.code) {
      setBook(data)
    } else if (data && data.code) {
      history.push('/login')
    }
  }

  useEffect(() => {
    getBook()
  }, [user.role]);

  const showAddModal = () => {
    if (!user.role) {
      history.push('/login')
    } else {
      dispatch({
        type: ADD_CARD,
        payload: book
      })
      setAddModal(true)
    }
  }

  const addBook = () => {
    setAddModal(false)
    history.push('/card')
  }

  return (
    <div className={`${styles.pageStyle} ${styles.pageDetailStyle}`}>
      <div className={styles.topBar}>
        <span className="s1" onClick={() => history.goBack()}>Shop</span>
        <span className="s2"> ＞ </span>
        <span className="s3">{book.title}</span>
      </div>

      <div className="books-wrap books-wrap-detail">
        <div className="item" >
          <img src={book.cover} alt="" className="detail-img" />
          <div style={{ marginLeft: '40px' }}>
            <p className={styles.mainText}>{book.title}</p>
            <p>
              <span>Subject : </span>
              <span style={{ color: '#999' }}>{book.subject}</span>
            </p>
            <p>
              <span>Grade : </span>
              <span style={{ color: '#999' }}>{book.grade}</span>
            </p>
            <p>
              <span>Author Name : </span>
              <span style={{ color: '#999' }}>{book.author}</span>
            </p>
            <p>
              <span>Published Time : </span>
              <span style={{ color: '#999' }}>{book.publish_time ? moment(new Date(book.publish_time)).format('YYYY-MM-DD HH:mm:ss') : ''}</span>
            </p>
            <p>
              <span>Using Period : </span>
              <span style={{ color: '#999' }}>{book.used_period}</span>
            </p>

            <div style={{ marginTop: '30px' }}>
              <span style={{ fontSize: '36px', marginRight: '20px' }}>{`$ ${book.sale_price}`} </span>
              <span style={{ fontSize: '14px', color: '#999', textDecoration: 'line-through' }}>{`$${book.original_price}`} </span>
            </div>

            {
              Object.keys(book).length > 0 ? <div className="add-card-btn" onClick={showAddModal}>
                Add to Cart
              </div> : null
            }

          </div>
        </div>
      </div>

      {
        addModal ?
          <ConfrimModal
            msg="Added to Cart"
            confirmText="Go to Cart"
            onConfirm={addBook}
            onClose={() => setAddModal(false)}
          ></ConfrimModal>
          : null
      }
    </div >
  )
}

export default Auth
