import React, { useState, useEffect, useRef } from 'react'
import RightIcon from '../../images/right-icon.png'
import CloseIcon from '../../images/close-icon.png'

import './styles.css'

const ComfirmModal = (props) => {
  const { msg = "", open = false, confirmText = 'add', onClose, onConfirm } = props
 
  const closeModal = () => {
    onClose && onClose()
  }
  return (
    <div className="modal-confirm-wrap">
      <div className="modal-bg"></div>
      <div className="modal-body">
        <div className="content">
          <img src={RightIcon} alt="" className="notice-icon" />
          <p className="msg">{msg}</p>
          <span className="btn-confirm" onClick={onConfirm}>{confirmText}</span>
        </div>
        <span className="close-btn" onClick={closeModal}>
          <img src={CloseIcon} alt="" className="close-icon"/>
        </span>
      </div>
    </div>
  )
}

export default ComfirmModal;