const subjectList = [
    {
        value: 'English',
        label: 'English',
    },
    {
        value: 'French',
        label: 'French',
    },
    {
        value: 'Spanish',
        label: 'Spanish',
    },
    {
        value: 'Geography',
        label: 'Geography',
    },
    {
        value: 'Science',
        label: 'Science',
    },
    {
        value: 'Mathematic',
        label: 'Mathematic',
    },
    {
        value: 'History',
        label: 'History',
    },
    {
        value: 'Computer',
        label: 'Computer',
    },
    {
        value: 'Art',
        label: 'Art',
    },
];

const gradeList = [
    {
        value: 7,
        label: 'Grade 7',
    },
    {
        value: 8,
        label: 'Grade 8',
    },
    {
        value: 9,
        label: 'Grade 9',
    },
    {
        value: 10,
        label: 'Grade 10',
    },
    {
        value: 11,
        label: 'Grade 11',
    },
    {
        value: 12,
        label: 'Grade 12',
    },
]

const periodList = new Array(60).fill(0).map((item, index) => {
    return {
        value: index + 1,
        label: index + 1,
    }
})

const subjectListAll = [
    {
        value: 'all',
        label: 'all',
    },
].concat(subjectList)

const gradeListAll = [
    {
        value: 'all',
        label: 'all',
    },
].concat(gradeList)

export {
    subjectList,
    gradeList,
    periodList,
    subjectListAll,
    gradeListAll,
}