import { makeStyles } from "@material-ui/core";

const styles = makeStyles((theme) => ({
  pageStyle: {
    fontFamily: 'Helvetica-Bold, Helvetica',
    width: '100%',
    minHeight: '100%',
    background: '#F9F6EB',
    border: '1px solid #F9F6EB',
    color: '#261C45',
  },
  backIcon: {
    color: '#572BA0',
  },
  formWrap: {
    margin: '60px auto',
    width: '672px',
  },
  formItem: {
    marginTop: '20px',
  },
  inputField: {
    marginTop: '6px',
    width: '672px',
    height: '42px',
    background: '#FFFFFF',
    borderRadius: '4px',
    border: '1px solid #D9D9D9',
    padding: '10px 13px',
    fontFamily: 'PingFangSC-Regular, PingFang SC',
    '&:focus-visible': {
      outline: 'none',
      border: '1px solid #e0c9b4',
    }
  },
  errorField: {
    border: '1px solid #D63232',
    fontFamily: 'PingFangSC-Regular, PingFang SC',
  },
  submitBtn: {
    width: '100%',
    height: '56px',
    background: '#261C45',
    borderRadius: '28px',
    border: '1px solid #979797',
    textAlign: 'center',
    fontSize: '25px',
    fontFamily: 'Helvetica-Bold, Helvetica',
    fontWeight: 'bold',
    color: '#F9F6EB',
    lineHeight: '56px',
    marginTop: '30px',
    cursor: 'pointer',
  },
  picker: {
    marginTop: '6px',
    width: '672px',
    height: '42px',
    background: '#FFFFFF',
    borderRadius: '4px',
    border: '1px solid #D9D9D9',
    fontFamily: 'PingFangSC-Regular, PingFang SC',
  },
  monthTip: {
    width: '80px',
    height: '42px',
    background: '#fff',
    borderRadius: '4px',
    border: '1px solid #D9D9D9',
    textAlign: 'center',
    marginLeft: '16px',
    lineHeight: '42px',
    fontSize: '15px',
    fontFamily: 'PingFangSC-Regular, PingFang SC',
    fontWeight: 400,
    color: '#333333',
    marginTop: '6px',
  },
  uploadBox: {
    width: '357px',
    height: '357px',
    background: '#fff',
    borderRadius: '4px',
    border: '1px solid #D9D9D9',
    position: 'relative',
  },
  uploadInput: {
    width: '100%',
    height: '100%',
    position: "absolute",
    inset: 0,
    zIndex: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    fontSize: '14px',
    fontFamily: 'Helvetica',
    color: '#999',
    cursor: 'pointer',
    textAlign: 'center',
  },
  active: {
    position: 'relative',
    border: '1px solid #e0c9b4',
  },
  chooseIcon3: {
    position: 'absolute',
    right: '8px',
    bottom: '10px',
    width: '18px',
    height: '18px',
  },
  previewImg: {
    width: '357px',
    height: '357px',
    borderRadius: '4px',
    position: "absolute",
    inset: 0,
    zIndex: 1
  },
  totalInfo: {
    width: '1380px',
    height: '48px',
    background: '#F9F6EB',
    borderRadius: '9px',
    border: '1px solid #E0C9B4',
    margin: '60px auto 20px',
    padding: '0px 14px',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold'
  },
  detailInfo: {
    width: '1380px',
    borderRadius: '9px',
    border: '1px solid #E0C9B4',
    padding: '0px 14px',
    margin: '0 auto',
    display: 'flex',
    background: '#fff',
    fontSize: '13px',
    color: '#261C45',
    '& p': {
      lineHeight: '25px',
      height: '25px',
      marginBottom: '0px',
      fontWeight: 'normal',
      fontFamily: 'Helvetica',
    }
  },
  bookInfo: {
    display: 'flex',
    padding: '10px',
    fontFamily: 'Helvetica-Bold, Helvetica',
  },
  mainText: {
    fontSize: '19px',
    fontWeight: 'bold',
    marginTop: '10px',
    lineHeight: '30px',
    marginBottom: '10px !important',
    fontFamily: 'Helvetica-Bold, Helvetica !important'
  },
  backToShopBtn: {
    width: '420px',
    height: '56px',
    background: '#261C45',
    borderRadius: '28px',
    border: '1px solid #979797',
    lineHeight: '56px',
    textAlign: 'center',
    margin: '60px auto 0px',
    fontSize: '25px',
    fontFamily: 'Helvetica-Bold, Helvetica',
    fontWeight: 'bold',
    color: '#F9F6EB',
  },

}));

export default styles;