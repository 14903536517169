import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Container } from "@material-ui/core";
import Entry from './components/Entry/Entry'
import Home from "./components/HomePageComponents/Home.js";
import Account from './components/Account/Account';
import Loading from "./components/Loading/Loading.js";
import Login from './components/Login/Login';
import Register from './components/Register/Register';
import Shop from './components/Shop/Shop'
import GoodsDetail from './components/Shop/GoodsDetail'
import Card from './components/Card/Card'
import CardOrder from './components/Card/CardOrder'
import CardOrderResult from './components/Card/CardOrderResult'
import MyBooks from './components/MyBooks/MyBooks'
import MyOrders from './components/MyOrders/MyOrders'
import Navbar from "./components/Navbar/Navbar";
import About from "./components/AboutUsComponents/About.js";
import School from './components/School/School'
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { AlertTitle } from "@material-ui/lab";
import { green } from "@material-ui/core/colors";
import history from "./history/history.js";
import { useDispatch, useSelector } from "react-redux";
import { socket } from "./service/socket";
import { GET_NOTIFICATION, CLEAR_NOTIFICATION } from "./constants/actions.js";
import Detail from "./components/AboutUsComponents/Description/Detail";
import SelectToDo from "./components/SelectToDo/SelectToDo";
import SelectGrade from "./components/SelectToDo/SelectGrade";
import SelectSubject from "./components/SelectToDo/SelectSubject";
import UploadBook from './components/UploadBook/UploadBook'
import UploadBookResult from './components/UploadBook/UploadBookResult'

import Test from './components/test'

const App = () => {
  const dispatch = useDispatch();

  const notification = useSelector((state) => state.notification);
  const navVisible = useSelector((state) => state.visible);

  console.log('rrrrrr', navVisible)

  const [shownoti, setShowNoti] = useState(false);
  const user = JSON.parse(localStorage.getItem("profile"));

  // useEffect(() => {
  //   if (notification.content) {
  //     if (notification.from !== user?.profile?.id) setShowNoti(true);
  //   }
  // }, [notification]);

  useEffect(() => {
    if (localStorage.getItem("profile")) {
      dispatch({ type: 'GET_PROFILE', payload: JSON.parse(localStorage.getItem("profile")) });
      // socket.connect();

      // const id = JSON.parse(localStorage.getItem("profile")).profile.id;
      // socket.on("connect", () => {
      //   socket.emit("login", { id: id });
      // });
    }
    if (localStorage.getItem("__card__")) {
      dispatch({ type: 'INIT_CARD', payload: JSON.parse(localStorage.getItem("__card__")) });
    }
  }, []);

  useEffect(() => {
    socket.on("send_msg", (msg) => {
      dispatch({ type: GET_NOTIFICATION, payload: msg });
    });
  }, []);

  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  const handleCloseNoti = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowNoti(false);
    dispatch({ type: CLEAR_NOTIFICATION });
  };

  const handleClickNoti = () => {
    history.push(`/user/${notification.from}`);
  };
  function displayLoading() {
    return <Loading />;
  }
  useEffect(() => {
    displayLoading();
  }, []);
  return (
    <Router>
      <Container maxWidth="lg">
        {navVisible.navbar === 'hide' ? null : <Navbar />}
        {shownoti ? (
          <Snackbar
            style={{ top: "10%", left: "55%", cursor: "pointer" }}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
            open={shownoti}
            autoHideDuration={5000}
            onClose={handleCloseNoti}
            onClick={handleClickNoti}
          >
            <Alert onClose={handleCloseNoti} icon={false} severity="info">
              <AlertTitle>
                New Message &nbsp; &nbsp;
                <NotificationsActiveIcon
                  style={{
                    color: green[500],
                    float: "right",
                    marginTop: "0.1rem",
                  }}
                />
              </AlertTitle>

              <div style={{ width: "300px" }}>
                <p>{notification.content}</p>
                <div style={{ float: "right" }}>
                  from <strong>{notification.fromName}</strong>
                </div>
              </div>
            </Alert>
          </Snackbar>
        ) : null}
        <Switch>
          <Route exact path="/" component={Entry} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/account" component={Account} />
          <Route exact path="/selecttodo" component={SelectToDo} />
          <Route exact path="/selectgrade" component={SelectGrade} />
          <Route exact path="/selectsubject" component={SelectSubject} />
          <Route exact path="/shop" component={Shop} />
          <Route exact path="/book/:bookId" component={GoodsDetail} />
          <Route exact path="/card" component={Card} />
          <Route exact path="/card-order" component={CardOrder} />
          <Route exact path="/card-order-result" component={CardOrderResult} />
          <Route exact path="/my-orders" component={MyOrders} />

          <Route exact path="/upload-book" component={UploadBook} />
          <Route exact path="/upload-book-result" component={UploadBookResult} />
          <Route exact path="/my-books" component={MyBooks} />
          <Route exact path="/test" component={Test} />
          <Route exact path="/aboutus" component={About} />
          <Route exact path="/aboutus/:name" component={Detail} />
          <Route exact path="/school" component={School} />
        </Switch>
        {/* <Footer /> */}
      </Container>
    </Router>
  );
};

export default App;
