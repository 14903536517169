import { makeStyles } from "@material-ui/core";

const styles = makeStyles(() => ({

    paper: {
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: "100%",
        zIndex: "-1",
        display: 'flex',
        alignItems: "center",
        justifyContent: "center",
        transform: "translateY(10%,0%)",
        marginTop: '10px',
        "@media (max-width : 700px)": {
            height: "600px",
        },
    },
    content: {
        top: "50%",
        left: "30%",
        zIndex: "10",
        position: "absolute",
        transform: "translate(-50%,-50%)",
        color: "#eae7dc",
        "@media (max-width : 700px)": {
            textAlign: "center",
            top: "50%",
            left: "50%",
            fontSize: "50px",
        },
    },

    contentBold: {
        background: "-webkit-linear-gradient(#e73426, #e85a4f)",
        "-webkit-background-clip": "text",
        "-webkit-text-fill-color": "transparent",
        "@media (max-width : 700px)": {
            textAlign: "center",
            top: "30%",
            left: "50%",
            fontSize: "50px",
        },
    },
    parent: {
        width: "100%",
        padding: '102px 30px 20px',
    },
    topBar: {
        height: '60px',
        background: '#F9F6EB',
        border: '1px solid #F9F6EB',
        display: 'flex',
        alignItems: 'center',
        fontSize: '16px',
        fontFamily: 'PingFangSC-Regular, PingFang SC',
        color: '#999',
        padding: '0px 20px',
        '& .s1': {
            cursor: 'pointer',
        },
        '& .s2': {
            margin: '0px 6px',
        },
        '& .s3': {
            color: '#333',
        }
    },
    topText: {
        fontSize: '48px',
        fontFamily: 'Helvetica - Bold, Helvetica',
        fontWeight: 'bold',
        color: '#1B2150',
        lineHeight: '58px',
        marginTop: '40px',
        textAlign: 'center',
    },
    infoText: {
        color: '#1B2150',
        "& span": {
            fontFamily: 'Helvetica-Bold',
            lineHeight: '40px',
            height: '40px',
            display: 'inline-block',
            marginRight: '5px'
        }
    }
}));

export default styles;
