import { makeStyles } from "@material-ui/core";

const styles = makeStyles((theme) => ({
  pageStyle: {
    fontFamily: 'Helvetica-Bold, Helvetica',
    width: '100%',
    minHeight: '100%',
    background: '#F4F4F4',
    border: '1px solid #F9F6EB',
    color: '#261C45',
    padding: '102px 30px 20px',
  },
  pageDetailStyle: {
    background: '#fff',
  },
  formItem: {
    display: 'flex',
    alignItems: 'center',
    width: '260px',
  },
  selectName: {
    verticalAlign: 'baseline',
    svg: {
      verticalAlign: 'baseline',
    }
  },
  totalInfo: {
    height: '48px',
    background: '#F9F6EB',
    borderRadius: '9px',
    border: '1px solid #E0C9B4',
    margin: '20px auto 20px',
    padding: '0px 14px',
    display: 'flex',
    alignItems: 'center',
  },
  detailInfo: {
    borderRadius: '9px',
    border: '1px solid #E0C9B4',
    padding: '0px 14px',
    margin: '0 auto',
    display: 'flex',
    background: '#fff',
    fontSize: '13px',
    color: '#261C45',
    marginBottom: '15px',
    '& p': {
      lineHeight: '25px',
      height: '25px',
      marginBottom: '0px',
      fontWeight: 'normal',
      fontFamily: 'Helvetica',
    }
  },
  bookInfo: {
    display: 'flex',
    padding: '10px',
    fontFamily: 'Helvetica-Bold, Helvetica',
  },
  mainText: {
    fontSize: '19px',
    fontWeight: 'bold',
    marginTop: '10px',
    lineHeight: '30px',
    marginBottom: '10px !important',
    fontFamily: 'Helvetica-Bold, Helvetica !important'
  },
}));

export default styles;