import React from "react";
import Carousel from "react-material-ui-carousel";
import useStyles from "./styles.js";
import descData from "./descData.js";
import bgImg from "../img/img.png"
import hu from "../../../images/hu.png"
import sun from "../../../images/sun.png"
import history from "../../../history/history";

const Description = () => {
    const classes = useStyles();
    function handleClick(name) {
        history.push(`/aboutus/${name}`);
        window.location.reload()
    }
    const Item = (props) => {
        return (
            <div className={classes.parent} style={{ paddingTop: '150px', height: '100%', backgroundImage: 'url(' + bgImg + ')', backgroundSize: '100% 100%' }}>
                <div
                    className={classes.paper}
                    style={{
                    }}
                >
                    <div style={{ padding: '0px 50px 120px' }}>
                        <div style={{
                            color: '#1B2150',
                            paddingLeft: 62,
                            fontFamily: 'Helvetica-Bold, Helvetica',
                            fontSize: 48,
                            marginBottom: 25,
                        }}>Design Proposal
                        </div>
                        <div style={{
                            color: '#1B2150',
                            paddingLeft: 62,
                            fontFamily: 'Helvetica',
                            fontSize: 24,
                            width: 610,
                            lineHeight: '40px'
                        }}>
                            {`We believe the current system for students to purchase textbooks and course materials is
                            inefficient. We believe the main cause of this is due to
                            The system is not owned by the school
                            Storage is far away from Appleby
                            We believe by making an online bookstore within Appleby we can:
                            Make the book purchasing more convenient
                            Create a sustainable loop of material recycling
                            We believe this can build a more sustainable community and give back to our own community.`}

                        </div>
                    </div>
                                    </div>
            </div>
        );
    };
    return (
        <Carousel
            indicators={false}
            animation="fade"
            autoPlay={true}
            interval={10000}
            stopAutoPlayOnHover={false}
            navButtonsAlwaysInvisible={true}
            style={{ height: '100%', paddingBottom: '100px' }}
        >
            {descData.map((item, index) => (
                <Item key={index} item={item} />
            ))}
        </Carousel>
    );
};

export default Description;
