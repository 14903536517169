import { makeStyles } from "@material-ui/core";

const styles = makeStyles((theme) => ({
  pageStyle: {
    fontFamily: 'Helvetica-Bold, Helvetica',
    width: '100%',
    minHeight: '100%',
    background: '#F9F6EB',
    border: '1px solid #F9F6EB',
    color: '#261C45',
  },
  backIcon: {
    color: '#572BA0',
  },
  formWrap: {
    margin: '60px auto',
    width: '420px',
  },
  formItem: {
    marginTop: '20px',
  },
  inputField: {
    marginTop: '6px',
    width: '420px',
    height: '42px',
    background: '#FFFFFF',
    borderRadius: '4px',
    border: '1px solid #D9D9D9',
    padding: '10px 13px',
    fontFamily: 'PingFangSC-Regular, PingFang SC',
  },
  errorField: {
    border: '1px solid #D63232',
    fontFamily: 'PingFangSC-Regular, PingFang SC',
  },
  submitBtn: {
    width: '420px',
    height: '56px',
    background: '#261C45',
    borderRadius: '28px',
    border: '1px solid #979797',
    textAlign: 'center',
    fontSize: '25px',
    fontFamily: 'Helvetica-Bold, Helvetica',
    fontWeight: 'bold',
    color: '#F9F6EB',
    lineHeight: '56px',
    marginTop: '30px',
    cursor: 'pointer',
  },
  registerBtn: {
    textAlign: 'center',
    fontSize: '17px',
    fontFamily: 'Helvetica',
    color: '#261C45',
    marginTop: '30px',
    textDecoration: 'underline',
    cursor: 'pointer',
  }
}));

export default styles;