import React, { useEffect, useState } from "react";
import {
    AppBar,
    Toolbar,
    Typography,
    Button,
    IconButton,
    Drawer,
    Link,
    Avatar,
} from "@material-ui/core";
import logo from "../../logo/final.png";
import shopCar from '../../images/shop-car.png';
import MenuIcon from "@material-ui/icons/Menu";
import useStyles from "./styles.js";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import { LOGOUT } from "../../constants/actions";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import decode from "jwt-decode";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { logoutUser } from "../../actions/user";

const Navbar = () => {
    const {
        parentTool,
        midNavbar,
        menuButton,
        menuButton2,
        appBar,
        toolbar,
        drawerContainer,
        image1,
        image2,
    } = useStyles();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user)
    const history = useHistory();
    const [state, setState] = useState({
        mobileView: false,
        drawerOpen: false,
    });
    const { mobileView, drawerOpen } = state;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (path) => {
        setAnchorEl(null);
        path && history.push(path)
    };

    // useEffect(() => {
    //     if (user) {
    //         const token = user.token;
    //         const decodedToken = decode(token);

    //         if (decodedToken.exp * 1000 < new Date().getTime()) logout();
    //     }
    // }, []);

    // const logout = () => {
    //     dispatch(logoutUser());
    //     setUser(null);
    //     history.push("/home");
    // };

    useEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < 900 && window.innerWidth > 100
                ? setState((prevState) => ({ ...prevState, mobileView: true }))
                : setState((prevState) => ({ ...prevState, mobileView: false }));
        };
        setResponsiveness();

        window.addEventListener("resize", () => setResponsiveness());
    }, []);

    const signOut = () => {
        setAnchorEl(null);
        dispatch(logoutUser());
        history.push("/home");
    }

    const getDrawerChoices = () => {
        return (
            <>
                <Link
                    to="/home"
                    component={RouterLink}
                    color="inherit"
                    className={menuButton}
                    key="Home"
                >
                    <MenuItem>Home</MenuItem>
                </Link>

                <Link
                    to="/all"
                    component={RouterLink}
                    color="inherit"
                    className={menuButton}
                    key="Books"
                >
                    <MenuItem>Books</MenuItem>
                </Link>
                <Link
                    to="/aboutus"
                    component={RouterLink}
                    color="inherit"
                    className={menuButton}
                    key="About Us"
                >
                    <MenuItem>About Us</MenuItem>
                </Link>
                <Link
                    to="/add"
                    component={RouterLink}
                    className={menuButton}
                    key="Sell Books"
                >
                    <MenuItem style={{ backgroundColor: "#e98074" }}>Sell Books</MenuItem>
                </Link>
            </>
        );
    };

    const goStarted = () => {
        if (user.token) {
            history.push('/selecttodo')
        } else {
            history.push('/login')
        }
    }

    const goCard = () => {
        if (user.token) {
            history.push('/card')
        } else {
            history.push('/login')
        }
    }

    const getMenuButtons = () => {
        return (
            <>
                <div className={midNavbar}>
                    <Button component={RouterLink} to="/shop" className={menuButton} style={{ textTransform: 'none' }}>
                        Shop
                    </Button>
                    <Button component={RouterLink} to="/aboutus" className={menuButton} style={{ textTransform: 'none' }}>
                        About Us
                    </Button>
                    <Button onClick={goStarted} className={menuButton} style={{ textTransform: 'none' }}>
                        Get Started
                    </Button>
                </div>
                {user.token ? (
                    <>
                        {/* <Button
                            ref={anchorRef}
                            aria-controls={open ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            onClick={handleToggle}
                            >
                            Toggle Menu Grow
                        </Button>
                        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                        {({ TransitionProps, placement }) => (
                            <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                            >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                    <MenuItem onClick={handleClose}>Profile</MenuItem>
                                    <MenuItem onClick={handleClose}>My account</MenuItem>
                                    <MenuItem onClick={handleClose}>Logout</MenuItem>
                                </MenuList>
                                </ClickAwayListener>
                            </Paper>
                            </Grow>
                        )}
                        </Popper> */}

                        <Button
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            className={menuButton2}
                            onClick={handleClick}
                            style={{ textTransform: 'none' }}
                        // onMouseOver={(ev) => buttonMouse(ev)}
                        // onMouseOut={() => setAnchorEl(null)}
                        >
                            <span>{user.username}</span>
                            <ArrowDropDownIcon />
                        </Button>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            getContentAnchorEl={null}
                            anchorOrigin={{ vertical: 58, horizontal: 'left' }}

                        >
                            <MenuItem
                                onClick={() => handleClose('/account')}
                                style={{ width: '140px' }}
                            >
                                Account
                            </MenuItem>
                            <MenuItem
                                onClick={() => handleClose('/my-orders')}
                            >
                                Orders
                            </MenuItem>
                            <MenuItem
                                onClick={() => handleClose('/my-books')}
                            >
                                Books
                            </MenuItem>
                            <MenuItem
                                onClick={() => signOut()}
                            >
                                Sign out
                            </MenuItem>
                        </Menu>
                    </>
                ) : (
                    <>
                        <Button component={RouterLink} to="/login" className={menuButton} style={{ textTransform: 'none' }}>
                            Sign In
                        </Button>
                    </>
                )}

                <Link onClick={goCard} color="inherit" style={{cursor: "pointer"}}>
                    <img className={image2} src={shopCar} alt="BookXchanger" />
                </Link>

                {/* <Button
                    component={RouterLink}
                    to="/add"
                    style={{
                        padding: " 10px",
                        margin: "5px",
                        color: "white",
                        backgroundColor: "#e98074",
                    }}
                    className={sellButton}
                >
                    Sell Books
                </Button> */}
            </>
        );
    };

    const displayDesktop = () => {
        return (
            <>
                <div style={{
                    display: "flex",
                    alignItems: "center"
                }}>
                    <img className={image1} src={logo} alt="BookXchanger" onClick={() => history.push('/home')} />

                    <div>
                        <div style={{
                            color: "#F9F4F3",
                            fontFamily: "Hei",
                            fontSize: "21px"
                        }} onClick={() => history.push('/home')}>Appleby Online Bookstore
                        </div>
                        <div style={{
                            color: "#E87542",
                            fontFamily: "Hei",
                            fontSize: "16px"
                        }} onClick={() => history.push('/school')}>Appleby College
                        </div>
                    </div>
                </div>

                <Toolbar className={toolbar}>{getMenuButtons()}</Toolbar>
            </>
        );
    };

    const displayMobile = () => {
        const handleDrawerOpen = () => {
            setState((prevState) => ({ ...prevState, drawerOpen: true }));
        };
        const handleDrawerClose = () => {
            setState((prevState) => ({ ...prevState, drawerOpen: false }));
        };

        return (
            <>
                <Toolbar className={parentTool}>
                    <IconButton
                        {...{
                            edge: "start", //Allows Button to be Positioned at the start
                            color: "inherit", //lets the icon the color specified to closest top level component
                            "aria-label": "menu", //These two meant for screen readers to notify users ,this element is menu , this element is pop up
                            "aria-haspopup": "true",
                            onClick: handleDrawerOpen,
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Drawer
                        {...{
                            anchor: "left",
                            open: drawerOpen,
                            onClose: handleDrawerClose,
                        }}
                    >
                        <div className={drawerContainer}>{getDrawerChoices()}</div>
                    </Drawer>

                    <Link component={RouterLink} to="\" color="inherit">
                        <img className={image1} src={logo} alt="BookXchanger" />
                    </Link>
                </Toolbar>

                {/* {user ? (
                    <div className={mobileloginMenu}>
                        <Button
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={handleClick}
                        >
                            <Avatar src={user?.profile?.profilePic} alt={user?.profile?.name}>
                                {user?.profile?.name.charAt(0)}
                            </Avatar>
                        </Button>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            style={{ color: "pink" }}
                        >
                            <MenuItem
                                onClick={handleClose}
                                style={{ backgroundColor: "#e85a4f" }}
                            >
                                <Button component={RouterLink} to="/profile">
                                    <Typography
                                        variant="h6"
                                        style={{
                                            fontWeight: "400",
                                            boxShadow: " none",
                                            fontSize: "1.2rem",
                                            color: "white",
                                        }}
                                    >
                                        My Profile
                                    </Typography>
                                </Button>
                            </MenuItem>
                            <MenuItem
                                onClick={handleClose}
                                style={{ backgroundColor: "#e85a4f" }}
                            >
                                <Button component={RouterLink} to="/wishlist">
                                    <Typography
                                        variant="h6"
                                        style={{
                                            fontWeight: "400",
                                            boxShadow: " none",
                                            fontSize: "1.2rem",
                                            color: "white",
                                        }}
                                    >
                                        Wishlist
                                    </Typography>
                                </Button>
                            </MenuItem>
                            <MenuItem
                                onClick={handleClose}
                                style={{ backgroundColor: "#e85a4f" }}
                            >
                                <Button component={RouterLink} to="/auth" onClick={logout}>
                                    <Typography
                                        variant="h6"
                                        style={{
                                            fontWeight: "400",
                                            boxShadow: " none",
                                            fontSize: "1.2rem",
                                            color: "white",
                                        }}
                                    >
                                        Logout
                                    </Typography>
                                </Button>
                            </MenuItem>
                        </Menu>
                    </div>
                ) : (
                    <div className={mobileloginMenu}>
                        <Button component={RouterLink} to="/auth" className={menuButton}>
                            Sign Up
                        </Button>
                    </div>
                )} */}


            </>
        );
    };

    return (
        <>
            <AppBar className={appBar} position="fixed">
                {mobileView ? displayMobile() : displayDesktop()}
            </AppBar>

            {/* <div className={appBarSpacer}></div> */}
        </>
    );
};

export default Navbar;
