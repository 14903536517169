import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { SET_NAVBAR_SHOW, SET_NAVBAR_HIDE } from '../../constants/actions.js'
const Entry = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch({
      type: SET_NAVBAR_HIDE,
      payload: {
        navbar: 'hide',
      },
    })
    return () => {
      dispatch({
        type: SET_NAVBAR_SHOW,
        payload: {
          navbar: 'show',
        },
      })
    }
  }, [dispatch])

  useEffect(() => {
    setTimeout(() => {
      history.push('/home')
    }, 2000)
  }, [history]);
  return (
    <div style={{
      width: '100%',
      height: '100%',
      background: '#261C45',
      border: '1px solid #261C45',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <p style={{
        fontSize: '72px',
        fontFamily: 'Helvetica-Bold, Helvetica',
        fontWeight: 'bold',
        color: '#F9F4F3',
      }}>Appleby Online Bookstore</p>
    </div>
  );
};

export default Entry;
