import { makeStyles } from "@material-ui/core";


export default makeStyles((theme) => ({
    select: {
        backgroundColor: "#F9F6EB",
        width: window.innerWidth,
        height: window.innerHeight,
        fontFamily: 'Hei',
    },
    title: {
        fontFamily: 'Helvetica',
        fontSize: 24,
        fontWeight: 400
    },
    pageStyle: {
        fontFamily: 'Helvetica-Bold, Helvetica',
        width: '100%',
        minHeight: '100%',
        background: '#F9F6EB',
        color: '#261C45',
    },
    backIcon: {
        color: '#572BA0',
    },
    selBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '60px',
    },
    selBox2: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '60px auto 0px',
        width: '480px',
        flexWrap: 'wrap',
    },
    selBox3: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '60px auto 0px',
        width: '600px',
        flexWrap: 'wrap',
    },
    chooseDiv: {
        width: '200px',
        height: '200px',
        background: '#FFFFFF',
        border: '1px solid #D9D9D9',
        textAlign: 'center',
        lineHeight: '200px',
        fontSize: '29px',
        fontFamily: 'Helvetica-Bold, Helvetica',
        fontWeight: 'bold',
        color: '#261C45',
        position: 'relative',
    },
    chooseDiv2: {
        width: '136px',
        height: '92px',
        background: '#FFFFFF',
        border: '1px solid #D9D9D9',
        textAlign: 'center',
        lineHeight: '92px',
        fontSize: '17px',
        fontFamily: 'Helvetica-Bold, Helvetica',
        fontWeight: 'bold',
        color: '#261C45',
        position: 'relative',
        marginBottom: '16px',
    },
    chooseDiv3: {
        width: '176px',
        height: '56px',
        background: '#FFFFFF',
        border: '1px solid #D9D9D9',
        textAlign: 'center',
        lineHeight: '56px',
        fontSize: '17px',
        fontFamily: 'Helvetica-Bold, Helvetica',
        fontWeight: 'bold',
        color: '#261C45',
        position: 'relative',
        marginBottom: '16px',
    },
    active: {
        border: '1px solid #E5A477',
    },
    chooseIcon: {
        position: 'absolute',
        right: '8px',
        bottom: '6px',
        width: '34px',
        height: '34px',
    },
    chooseIcon2: {
        position: 'absolute',
        right: '8px',
        bottom: '6px',
        width: '27px',
        height: '27px',
    },
    chooseIcon3: {
        position: 'absolute',
        right: '8px',
        bottom: '6px',
        width: '20px',
        height: '20px',
    },
    nextIcon: {
        width: '65px',
        height: '65px',
        marginTop: '60px',
        cursor: 'pointer',
    }
}));
