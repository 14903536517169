import { makeStyles } from "@material-ui/core";

const styles = makeStyles((theme) => ({
  pageStyle: {
    fontFamily: 'Helvetica-Bold, Helvetica',
    width: '100%',
    minHeight: '100%',
    background: '#F4F4F4',
    border: '1px solid #F9F6EB',
    color: '#261C45',
    padding: '102px 30px 20px',
  },
  pageDetailStyle: {
    background: '#fff',
  },
  formItem: {
    display: 'flex',
    alignItems: 'center',
    width: '300px',
  },
  selectName: {
    verticalAlign: 'baseline',
    svg: {
      verticalAlign: 'baseline',
    }
  },
  bookInfo: {
    display: 'flex',
    padding: '10px',
    fontFamily: 'Helvetica-Bold, Helvetica',
  },
  mainText: {
    fontWeight: 'bold',
    fontSize: '18px',
    marginBottom: '16px !important',
  },
  topBar: {
    height: '60px',
    background: '#F9F6EB',
    border: '1px solid #F9F6EB',
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
    fontFamily: 'PingFangSC-Regular, PingFang SC',
    color: '#999',
    padding: '0px 20px',
    '& .s1': {
        cursor: 'pointer',
    },
    '& .s2': {
        margin: '0px 6px',
    },
    '& .s3': {
        color: '#333',
    }
},
}));

export default styles;