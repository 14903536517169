import { SET_NAVBAR_SHOW, SET_NAVBAR_HIDE } from "../constants/actions";

const initVisible = {
  navbar: 'show'
}
const setNavVisible = (state = initVisible, action) => {
  switch (action.type) {
    case SET_NAVBAR_SHOW:
      return action.payload;
    case SET_NAVBAR_HIDE:
      return action.payload;
    default:
      return state;
  }
};

export default setNavVisible;