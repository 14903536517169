import React, { useState, useEffect, useRef, useCallback } from 'react'
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded'
import { useDispatch, useSelector } from 'react-redux'
import { SET_NAVBAR_SHOW, SET_NAVBAR_HIDE, GET_PROFILE, DEL_CARD } from '../../constants/actions.js'
import { Typography, Popover, ClickAwayListener, Grid, Checkbox } from '@material-ui/core'
import useStyles from './styles.js'
import api from '../../api/index'
import { useHistory, useParams } from 'react-router-dom'
import './style.css'
import ChooseIcon from '../../images/choose-icon.png'

const Auth = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const cardBooks = useSelector(state => state.card)
  const userInfo = useSelector(state => state.user)
  const styles = useStyles()
  const [drop_off_time, setDrop_off_time] = useState('Monday 12:30-13:00');
  const [booksList, setBooksList] = useState([]);
  const searchParams = new URLSearchParams(window.location.search);
  const [allPrice, setAllPrice] = useState(0);
  const idsQuery = searchParams.get('ids') || ''
  const ids = idsQuery.split(',').filter(Boolean).map(i => +i)
  const [tipMsg, setTipMsg] = useState('');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    let books = cardBooks.filter(book => ids.includes(book.id))
    setBooksList(books)
    let allPrice = books.reduce((b, i) => {
      return b += (+i.sale_price * 100)
    }, 0)
    setAllPrice(allPrice / 100)

  }, [cardBooks]);

  const submitOrder = async () => {
    let { data } = await api.bookOrder({
      pick_up_time: drop_off_time,
      books: ids
    })

    if (!data || !data.code) {
      dispatch({
        type: DEL_CARD,
        payload: booksList
      })
      history.push('/card-order-result?id=' + data.id)
    } else {
      let code = data.code
      switch (code) {
        case 300003:
          setTipMsg('Sold out')
          setOpen(true)
          break;
        default:
          break;
      }
    }
  }

  return (
    <div className="card-wrap card-order-wrap">
      <div
        style={{
          marginTop: '20px',
          marginLeft: '40px',
        }}
      >
        <ArrowBackRoundedIcon style={{ cursor: 'pointer', color: '#572BA0' }} fontSize="large" color="primary" onClick={() => history.goBack()} />
      </div>

      <div className="content">
        <Typography variant="h5" style={{ marginBottom: '30px', fontWeight: 'bold' }}>Drop Off Time</Typography>

        <div className={`${styles.mgt20} ${styles.inputField} ${drop_off_time === 'Monday 12:30-13:00' ? styles.active : ''}`} onClick={() => setDrop_off_time('Monday 12:30-13:00')}>
          <span>Monday 12:30-13:00</span>
          {drop_off_time === 'Monday 12:30-13:00' ? <img className={styles.chooseIcon3} src={ChooseIcon} alt="" /> : null}
        </div>
        <div className={`${styles.mgt20} ${styles.inputField} ${drop_off_time === 'Wednesday 12:30-13:00' ? styles.active : ''}`} onClick={() => setDrop_off_time('Wednesday 12:30-13:00')}>
          <span>Wednesday 12:30-13:00</span>
          {drop_off_time === 'Wednesday 12:30-13:00' ? <img className={styles.chooseIcon3} src={ChooseIcon} alt="" /> : null}
        </div>
        <div
          className={`${styles.mgt20} ${styles.inputField} ${drop_off_time === 'Friday 12:30-13:00' ? styles.active : ''}`}
          onClick={() => setDrop_off_time('Friday 12:30-13:00')}
        >
          <span>Friday 12:30-13:00</span>
          {
            drop_off_time === 'Friday 12:30-13:00' ?
              <img className={styles.chooseIcon3} src={ChooseIcon} alt="" />
              : null
          }
        </div>
        <div style={{ height: '50px' }}></div>
        <Typography variant="h5" style={{ marginBottom: '30px', fontWeight: 'bold' }}>Confirm Order Information</Typography>
        <div className="card-box">
          <Grid container spacing={0} className="card-list-title" style={{ background: '#fff' }}>
            <Grid item xs={6}>
              Book Information
            </Grid>
            <Grid item xs={2}>
              Price
            </Grid>
            <Grid item xs={2}>
              Quantity
            </Grid>
            <Grid item xs={2}>
              Subtotal
            </Grid>
          </Grid>
          {
            booksList.map(book => {
              return <Grid container spacing={0} className="card-list-body" key={book.id}>
                <Grid item xs={6} className="book-info">
                  <img src={book.cover} alt="" className="cover" />
                  <div className="title">{book.title}</div>
                </Grid>
                <Grid item xs={2}>
                  <div>${book.sale_price}</div>
                </Grid>
                <Grid item xs={2}>
                  1
                </Grid>
                <Grid item xs={2}>
                  <div>${book.sale_price}</div>
                </Grid>
              </Grid>
            })
          }
        </div>

        <div className="total-info">
          <div className="price">
            <span>Subtotal ({ids.length || 0} items):</span>
            <span className="red-text"> ${allPrice}</span>
          </div>

          <div className="info-line">
            <div className="time">
              <span style={{ fontWeight: 'bold' }}>Student Number: </span>
              <span>{userInfo.student_no || ''}</span>
            </div>
            <div className="time">
              <span style={{ fontWeight: 'bold' }}>Pick Up Time: </span>
              <span>{drop_off_time}</span>
            </div>
          </div>

          <div className="order-btn" onClick={submitOrder}>
            Submit Order
          </div>
        </div>

      </div>
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        anchorPosition={{
          top: 100,
        }}
        classes={{
          root: 'msgTip',
        }}
      >
        {tipMsg}
      </Popover>
    </div >
  )
}

export default Auth
