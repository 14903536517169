import React, { useState, useEffect } from 'react'
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded'
import { useDispatch, useSelector } from 'react-redux'
import { SET_NAVBAR_SHOW, SET_NAVBAR_HIDE } from '../../constants/actions.js'
import ChooseIcon from '../../images/choose-icon.png'
import NextIcon from '../../images/next-icon.png'
import useStyles from './styles.js'
import { useHistory } from 'react-router-dom'

const Auth = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const styles = useStyles()
    const [user, setUser] = useState({})
    const [chooseType, setChooseType] = useState('buy');
    useEffect(() => {
        dispatch({
            type: SET_NAVBAR_HIDE,
            payload: {
                navbar: 'hide',
            },
        })
        return () => {
            dispatch({
                type: SET_NAVBAR_SHOW,
                payload: {
                    navbar: 'show',
                },
            })
        }
    }, [dispatch])

    useEffect(() => {
        let _user = JSON.parse(localStorage.getItem('profile'))
        if (_user?.token) {
            setUser(_user)
        } else {
            history.push('/login')
        }
    }, [user?.token, history])

    const goNext = () => {
        if (chooseType === 'buy') {
            history.push(`/selectgrade`)
        } else {
            history.push(`/upload-book`)
        }
    }

    return (
        <div className={styles.pageStyle}>
            <div
                style={{
                    paddingTop: '30px',
                    marginLeft: '40px',
                }}
            >
                <ArrowBackRoundedIcon style={{ cursor: 'pointer' }} fontSize="large" color="primary" className={styles.backIcon} onClick={() => history.goBack()} />
            </div>
            <div style={{ textAlign: 'center' }}>
                <h3 style={{ marginTop: '60px' }}>Tell us how you get ready</h3>

                <div className={styles.selBox}>
                    <div className={`${styles.chooseDiv} ${chooseType === 'buy' ? styles.active : ''}`} style={{ marginRight: '16px' }} onClick={() => setChooseType('buy')}>
                        <p>Buy in</p>
                        {chooseType === 'buy' ? <img className={styles.chooseIcon} src={ChooseIcon} alt="" /> : null}
                    </div>
                    <div className={`${styles.chooseDiv} ${chooseType === 'sell' ? styles.active : ''}`} onClick={() => setChooseType('sell')}>
                        <p>Sell out</p>
                        {chooseType === 'sell' ? <img className={styles.chooseIcon} src={ChooseIcon} alt="" /> : null}
                    </div>
                </div>
                {chooseType ? <img className={styles.nextIcon} src={NextIcon} alt="" onClick={goNext} /> : null}
            </div>

        </div>
    )
}

export default Auth
