import React, { useState, useEffect, useCallback, useRef } from 'react'
import './style.css'
import useStyles from './styles.js'
import BgImg from '../../images/img.png'

const Auth = () => {
  const styles = useStyles()

  return (
    <div className={styles.pageStyle} style={{
      backgroundImage: 'url(' + BgImg + ')',
      backgroundSize: '100% 100%',
    }}>
      <div className={styles.pageContent}>
        <div className={styles.leftInfo}>
          <h2>Appleby College</h2>
          <div className={styles.infoItem}>
            <span className={styles.mainColor}>Founded :</span>
            <span>1911</span>
          </div>
          <div className={styles.infoItem}>
            <span className={styles.mainColor}>Location :</span>
            <span>540 Lakeshore Rd W Oakville, ON, Canada
            </span>
          </div>
          <div className={styles.infoItem}>
            <span className={styles.mainColor}>Area :</span>
            <span>242,811 meter square
            </span>
          </div>
          <div className={styles.infoItem}>
            <span className={styles.mainColor}>Website :</span>
            <span>https://www.appleby.on.ca</span>
          </div>
        </div>

        <div className={styles.videoBox}>
          <iframe src="https://www.youtube.com/embed/Vkab2NefxUs?si=OltS9FpNcAPbFhwI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      </div>
    </div >
  )
}

export default Auth
