import React from "react";
import useStyles from "./styles.js";
import { useHistory, useParams } from "react-router-dom";
import bgImg from "../img/img.png";
import hu from "../../../images/hu.png"
import sun from "../../../images/sun.png"

const HuPersonalInfo = () => {
    const classes = useStyles()
    return (
        <>
            <div style={{
                color: '#1B2150',
                fontFamily: 'Helvetica',
                fontSize: 24,
                width: 580,
                margin: '0px 60px',
            }} className={classes.infoText}>
                <span>School: </span>Appleby College
                <br />
                <span>Age: </span>15
                <br />
                <span>Graduating Years: </span> 2025
                <br />
                <span>Student number: </span>2025163
                <br />
                <span>Co-curricular: </span>Computer graphic and animation
                <br />
                <span>Club: </span> Art Council
                <br />
                <span>Interest: </span>Workout, art, business
                <br />
                <span>Role on the team: </span> Website designer, programmer, operator, presenter and founder
            </div>

            <div style={{
                color: '#666',
                fontFamily: 'Helvetica',
                lineHeight: '30px',
                fontSize: 16,
                width: 300,
                height: 400,
                background: '#F4F4F4',
                borderRadius: '15px',
                padding: '20px 30px',
                marginTop: '40px',
            }}>
                As a new international student in Appleby, I find it extremely complex and annoying to
                purchase my textbooks and course materials. It took me half a school year to even get my
                hands on my Science textbook. Because of this experience, I decided to make changes to what
                he believes is an issue that bothers fellow students.
            </div>
        </>
    )
}
const SunPersonalInfo = () => {
    const classes = useStyles()
    return (
        <>
            <div style={{
                color: '#1B2150',
                fontFamily: 'Helvetica',
                fontSize: 24,
                width: 580,
                margin: '0px 60px',
            }} className={classes.infoText}>
                <span>School: </span>Appleby College
                <br />
                <span>Age: </span>15
                <br />
                <span>Graduating Years: </span>2025
                <br />
                <span>Student number: </span>2025001
                <br />
                <span>Co-curricular: </span>Tennis
                <br />
                <span>Club: </span>Integrated Science
                <br />
                <span>Interest: </span>Biology, editing, and art
                <br />
                <span>Role on the team: </span>Context designer, programmer, presenter, and co-founder
            </div>

            <div style={{
                color: '#666',
                fontFamily: 'Helvetica',
                lineHeight: '30px',
                fontSize: 16,
                width: 300,
                height: 400,
                background: '#F4F4F4',
                borderRadius: '15px',
                padding: '20px 30px',
                marginTop: '40px',
            }}>
                Textbook purchasing has always been a challenge, its complex, expensive and slow. Despite this, the idea
                of making an online bookstore to replace the status quo didn’t spark until it was brought up by my
                roommate, Luke. After expressing this problem and discussing, we realized we can be the ones to make the
                change.

            </div>
        </>
    )
}
const Detail = () => {
    const history = useHistory();
    const classes = useStyles();
    let { name } = useParams()
    let pic = name === "hu" ? hu : sun
    let text = name === 'hu' ? 'Luke Hu - 2025163' : 'Ivan Sun - 2025001'
    return (
        <div>
            <div className={classes.parent} style={{
                backgroundImage: 'url(' + bgImg + ')',
                backgroundSize: '100% 100%',
            }}>
                <div className={classes.topBar}>
                    <span className="s1" onClick={() => history.goBack()}>About us</span>
                    <span className="s2"> ＞ </span>
                    <span className="s3">Resume</span>
                </div>
                <h3 className={classes.topText}>“Build a sustainable community”</h3>
                <div
                    className={classes.paper}

                >
                    <div style={{
                        backgroundImage: 'url(' + pic + ')',
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        width: 310,
                        height: 360,
                        marginLeft: 30,
                        position: 'relative'
                    }} >
                        <p style={{
                            position: 'absolute',
                            bottom: '-60px',
                            left: '60px',
                            fontFamily: 'Helvetica-Bold, Helvetica',
                            fontSize: '20px'
                        }}>{text}</p>
                    </div>
                    {name === "hu" ? <HuPersonalInfo></HuPersonalInfo> : <SunPersonalInfo></SunPersonalInfo>}
                </div>
            </div>
        </div>
    );
};

export default Detail;
