import { makeStyles } from "@material-ui/core";

const styles = makeStyles((theme) => ({
  pageStyle: {
    width: '100%',
    minHeight: '100%',
    background: '#fff',
    padding: '102px 30px 20px',
    fontFamily: 'Helvetica-Bold, Helvetica',
  },
  pageContent: {
    display: 'flex',
    margin: '70px 80px',
    justifyContent: 'center',
    '& h2': {
      fontSize: '48px',
      fontWeight: 'bold',
      color: '#1B2150',
      lineHeight: '58px',
      marginBottom: '40px',
    }
  },
  leftInfo: {
    minWidth: '600px',
  },
  infoItem: {
    fontSize: '24px',
    fontWeight: 'normal',
    color: '#1B2150',
    lineHeight: '40px',
    fontFamily: 'Helvetica',
  },
  mainColor: {
    fontWeight: 'bold',
    marginRight: '10px',
    display: 'inline-block',
  },
  videoBox: {
    width: '700px',
    height: '400px',
    marginLeft: '60px',
    flex: '0 0 700px',
    '& iframe': {
      width: '700px',
      height: '400px',
      border: 'none'
    }
  }
}));

export default styles;