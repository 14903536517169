import {
  ADD_CARD,
  DEL_CARD,
  INIT_CARD,
  PICK_CARD,
} from "../constants/actions";

// eslint-disable-next-line import/no-anonymous-default-export
export default (books = [], action) => {
  switch (action.type) {
    case INIT_CARD:
      return action.payload;
    case ADD_CARD:
      if (books.find(book => action.payload.id === book.id)) {
        return books
      } else {
        window.localStorage.setItem('__card__', JSON.stringify([action.payload, ...books]))
        return [action.payload, ...books]
      }
    case DEL_CARD:
      let res = books.filter((book) => !(action.payload.find(_book => _book.id === book.id)));
      window.localStorage.setItem('__card__', JSON.stringify(res))
      return res
    case PICK_CARD:
      let resp = books.filter((book) => action.payload.includes(book.is));
      return resp;
    default:
      return books;
  }
};
