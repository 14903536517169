import React, { useState, useEffect, useRef, useCallback } from 'react'
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded'
import { useDispatch, useSelector } from 'react-redux'
import { SET_NAVBAR_SHOW, SET_NAVBAR_HIDE, GET_PROFILE } from '../../constants/actions.js'
import { Typography, Popover, ClickAwayListener, Grid, Checkbox } from '@material-ui/core'
import useStyles from './styles.js'
import api from '../../api/index'
import { useHistory, useParams } from 'react-router-dom'
import './style.css'
import ConfrimModal from '../../comps/config-modal/ConfirmModal'
import { ADD_CARD, DEL_CARD } from '../../constants/actions'
import CardEmptyIcon from '../../images/card-empty-icon.png'

const Auth = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [checkedBooks, setCheckedBooks] = useState([]);
  const [checkedPrice, setCheckedPrice] = useState(0);
  const cardBooks = useSelector(state => state.card)
  const [booksList, setBooksList] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);

  useEffect(() => {
    setBooksList(cardBooks.map(book => {
      book.checked = false
      return book
    }))
  }, [cardBooks]);

  useEffect(() => {
    let allPrice = booksList.reduce((base, item) => {
      if (item.checked) {
        return base += +(item.sale_price * 100)
      } else {
        return base
      }
    }, 0)
    setCheckedPrice(allPrice / 100)

    let allChecked = booksList.filter(book => book.checked)
    setCheckedBooks(allChecked)

    setCheckedAll(booksList.every(book => book.checked))

  }, [booksList]);

  const checkAll = (e) => {
    let checked = e.target.checked
    setCheckedAll(checked)

    setBooksList(booksList.map(item => {
      item.checked = checked
      return item
    }))
  }


  const checkedBook = (e, book) => {
    let checked = e.target.checked
    let newList = booksList.map(item => {
      if (book.id === item.id) {
        item.checked = checked
      }
      return item
    })
    setBooksList(newList)
  }

  const delBook = (book) => {
    dispatch({
      type: DEL_CARD,
      payload: [book]
    })
  }

  const goCheckoutPage = () => {
    let ids = checkedBooks.map(book => book.id)
    history.push('/card-order?ids=' + ids)
  }
  return (
    <div className="card-wrap">
      {
        cardBooks.length > 0 ?
          <>
            <div className="top-box">
              <span className="s1">Your Card</span>

              {
                checkedBooks.length > 0 ?
                  <div className="price">
                    <span>Subtotal ({checkedBooks.length} items):</span>
                    <span style={{ color: '#D63232' }}> ${checkedPrice}</span>
                  </div> :
                  <div className="no-check-text">No items selected</div>
              }

              <div className={`go-checkout ${checkedBooks.length > 0 ? 'active' : ''}`} onClick={goCheckoutPage}>Proceed to checkout</div>
            </div>

            <div className="card-box">
              <Grid container spacing={0} className="card-list-title">
                <Grid item xs={1}>
                  <Checkbox
                    color="primary"
                    checked={checkedAll}
                    onChange={checkAll}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </Grid>
                <Grid item xs={3}>
                  Book Information
                </Grid>
                <Grid item xs={2}>
                  Price
                </Grid>
                <Grid item xs={2}>
                  Quantity
                </Grid>
                <Grid item xs={2}>
                  Subtotal
                </Grid>
                <Grid item xs={2}>
                  Operation
                </Grid>
              </Grid>
              {
                booksList.map(book => {
                  return <Grid container spacing={0} className="card-list-body" key={book.id}>
                    <Grid item xs={1}>
                      <Checkbox
                        color="primary"
                        checked={book.checked}
                        onChange={(e) => checkedBook(e, book)}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </Grid>
                    <Grid item xs={3} className="book-info">
                      <img src={book.cover} alt="" className="cover" />
                      <div className="title">{book.title}</div>
                    </Grid>
                    <Grid item xs={2}>
                      <div>${book.sale_price}</div>
                    </Grid>
                    <Grid item xs={2}>
                      1
                    </Grid>
                    <Grid item xs={2}>
                      <div>${book.sale_price}</div>
                    </Grid>
                    <Grid item xs={2}>
                      <div className="form-error" style={{ cursor: 'pointer', color: '#D63232', fontSize: "16px" }} onClick={() => delBook(book)}>Delete</div>
                    </Grid>
                  </Grid>
                })
              }
            </div>

          </> :
          <div className="empty-box">
            <img src={CardEmptyIcon} alt="" width="88" />
            <p style={{ color: '#BABABA', marginTop: '15px', fontSize: '16px' }}>Your Cart is empty.</p>
          </div>
      }
    </div >
  )
}

export default Auth
