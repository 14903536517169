import React, { useState, useEffect, useRef } from 'react'
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded'
import { useDispatch, useSelector } from 'react-redux'
import { SET_NAVBAR_SHOW, SET_NAVBAR_HIDE, GET_PROFILE } from '../../constants/actions.js'
import { Typography, Popover, ClickAwayListener } from '@material-ui/core'

import useStyles from './styles.js'
import { Link } from 'react-router-dom'
import api from '../../api'
import { useHistory } from 'react-router-dom'

const Auth = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [usernameTip, setUsernameTip] = useState('')
  const [ajaxing, setAjaxing] = useState(false)
  const { pageStyle, backIcon, formWrap, formItem, inputField, errorField, submitBtn } = useStyles()
  const initPage = useRef(false)
  const [studentTip, setStudentTip] = useState('')
  const [student_no, setStudent_no] = useState('')
  const [user, setUser] = useState({})
  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
  }

  const [tipMsg, setTipMsg] = useState('')

  // const open = Boolean(anchorEl);

  const [registerInfo, setRegisterInfo] = useState([
    {
      id: 'username',
      value: '',
      tip: '',
      validate: v => {
        if (!v) {
          return 'Enter your name'
        }
        return ''
      },
    },
    {
      id: 'student_no',
      value: '',
      tip: '',
      req: true,
      validate: student => {
        if (!student) {
          return 'Please enter you student number'
        } else {
          return ''
        }
      },
    },
  ])

  useEffect(() => {
    dispatch({
      type: SET_NAVBAR_HIDE,
      payload: {
        navbar: 'hide',
      },
    })
    return () => {
      dispatch({
        type: SET_NAVBAR_SHOW,
        payload: {
          navbar: 'show',
        },
      })
    }
  }, [dispatch])

  useEffect(() => {
    if (!initPage.current) {
      initPage.current = true
    } else {
      judgeForm()
    }
  }, [registerInfo])

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('profile')), () => {
      const token = user?.token
      if (!token) history.push('/login')
    })
  }, [user?.token, history])

  const judgeForm = () => {
    let allOk = registerInfo.every(item => {
      return !item.tip && item.value
    })
    return allOk
  }

  const validateForm = fn => {
    let newInfo = registerInfo.map(item => {
      let notice = item.validate(item.value)
      item.tip = notice
      item.id === 'username' && setUsernameTip(notice)
      return item
    })

    setRegisterInfo(newInfo)
    return newInfo
  }

  const saveAccount = async ev => {
    let newInfo = validateForm()
    let _allOk = judgeForm(newInfo)
    if (!_allOk) {
      return
    }
    let params = getParams()
    if (ajaxing) {
      return
    }
    setAjaxing(true)
    let { data } = await api.editUsername(params)
    setAjaxing(false)
    if (!data || !data.code) {
      let newUser = {
        ...user,
        ...params,
      }
      localStorage.setItem('profile', JSON.stringify(newUser))
      localStorage.setItem('__booktoken__', newUser.token)
      dispatch({
        type: GET_PROFILE,
        payload: newUser,
      })
      setTipMsg('Saved')
      setOpen(true)
      setTimeout(() => {
        history.goBack()
      }, 2000)
    } else {
      if (data && data.code) {
        switch (data.code) {
          case 100007:
            setUsernameTip('Your name is already in use')
            break
          default:
            break
        }
      }
      // setOpen(true);
    }
  }

  const setForm = e => {
    let { name, value } = e.target
    let _value = value
    if (name === 'student_no') {
      let v = value.replace(/[^0-9]+/, '')
      _value = v
      setStudent_no(v)
    }
    setRegisterInfo(info => {
      let _info = info.map(item => {
        if (item.id === name) {
          item.value = _value
          let notice = item.validate(_value)
          item.tip = notice
          name === 'username' && setUsernameTip(notice)
          name === 'student_no' && setStudentTip(notice)
        }
        return item
      })
      return _info
    })
  }

  const getParams = () => {
    let params = registerInfo.reduce((base, item) => {
      return {
        ...base,
        [item.id]: item.value,
      }
    }, {})
    return params
  }
  return (
    <div className={pageStyle}>
      <div
        style={{
          marginTop: '30px',
          marginLeft: '40px',
        }}
      >
        <Link to={'/home'} style={{ textDecoration: 'none' }}>
          <ArrowBackRoundedIcon style={{ cursor: 'pointer' }} fontSize="large" color="primary" className={backIcon} />
        </Link>
      </div>
      <div className={formWrap}>
        <Typography variant="h5">Your information</Typography>
        <div className={`${formItem} `}>
          <div htmlFor="">User Name</div>
          <input
            maxLength="50"
            name="username"
            placeholder="username"
            className={`${inputField} ${usernameTip ? errorField : ''}`}
            onChange={e => setForm(e)}
          />
          {usernameTip ? <div className="form-error">{usernameTip}</div> : null}
        </div>

        <div className={formItem}>
          <div htmlFor="">Student Number</div>
          <input
            maxLength="10"
            name="student_no"
            value={student_no}
            className={`${inputField} ${studentTip ? errorField : ''}`}
            placeholder="Student Number"
            onChange={e => setForm(e)}
          />
          {studentTip ? <div className="form-error">{studentTip}</div> : null}
        </div>

        <div className={submitBtn} onClick={ev => saveAccount(ev)}>
          Save
        </div>
      </div>

      <Popover
        open={open}
        onClose={() => setOpen(false)}
        anchorPosition={{
          top: 100,
        }}
        classes={{
          root: 'msgTip',
        }}
      >
        {tipMsg}
      </Popover>
    </div>
  )
}

export default Auth
